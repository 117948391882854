
// FOOTER

.b-footer._login {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 999; }

.b-footer {
	height: 149px;
	overflow: hidden;
	margin: -149px 0 0 0;
	background: rgba(0,0,0,.9);
	padding-top: 27px;
	text-align: center;
	color: #959595;
	font-size: 12px;

	.b-footer__socials {
		margin-bottom: 30px;

		a {
			display: inline-block;
			position: relative;

			&:hover {
				.i-edit_invis {
					visibility: visible;
					opacity: 1; } } }

		.i-edit_socials {
			width: 39px;
			height: 37px;
			margin-right: 35px; }

		.yt {
			width: 95px;
			height: 52px;
			background: url(/assets/img/socials_2.png) no-repeat 0 0; }

		.fb {
			background: url(/assets/img/socials_2.png) no-repeat -129px 0; }

		.vk {
			background: url(/assets/img/socials_2.png) no-repeat -204px 0; }

		.gp {
			background: url(/assets/img/socials_2.png) no-repeat -278px 0; }

		.feedback {
			background: url(/assets/img/socials_2.png) no-repeat -352px 0;
			margin: 0; }


		.i-edit_invis {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease; }

		.i-edit_invis_yt {
			width: 95px;
			height: 52px;
			background: url(/assets/img/socials_2.png) no-repeat 0 -56px; }

		.i-edit_invis_fb {
			background: url(/assets/img/socials_2.png) no-repeat -129px -56px; }

		.i-edit_invis_vk {
			background: url(/assets/img/socials_2.png) no-repeat -204px -56px; }

		.i-edit_invis_gp {
			background: url(/assets/img/socials_2.png) no-repeat -278px -56px; }

		.i-edit_invis_feedback {
			background: url(/assets/img/socials_2.png) no-repeat -352px 0;
			margin: 0; } } }

// FOOTER END
