body.join {
	background: url('/assets/img/login_fon.jpg') 0 0/cover; }

.j-trans-block {
	font-size: 30px;
	font-family: "RobotoLight",sans-serif;
	text-align: center;
	display: inline-block;
	padding: 60px 110px;
	background: rgba(255,255,255,.4);
	margin-top: 120px; }

.j-page-content {
	text-align: center;
	max-width: 1135px;
	overflow: hidden; }

.j-buttons-wrap {
	margin-top: 35px;
	text-align: center;

	label {
		font-size: 11.95px;
		margin-top: 10px;

		span {
			vertical-align: text-bottom; } } }

.j-page-button {
	width: 135px;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	color: #fff;
	margin: 0 5px;
	font-size: 20px;
	padding: 10px 0;
	transition: background 0.3s ease; }

.j-button_yes {
	background: #fe4b00;

	&:hover {
		background: #d54b08; } }

.j-button_no {
	background: #fff;
	color: #000;

	&:hover {
		background: #ECECEC; } }

.j-steps {
	margin: 45px 0 35px; }

.j-step-item {
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	font-family: "RobotoMedium",sans-serif;
	background: url("/assets/img/join-sprite.png")no-repeat 0 -29px;
	padding: 6px 0 4px 32px;
	color: #9ba5aa;

	span {
		border-bottom: 3px solid transparent;
		padding-bottom: 1px; } }

.j-step-active {
	background-position: 0 0;
	color: #000;

	span {
		border-bottom: 3px solid #ff5300; } }

.j-step-arrow {
	background-position: -4px -121px;
	padding: 6px 11px;
	margin-bottom: -1px; }

.j-page-block {
	background: rgba(255,255,255,.8);
	display: inline-block;
	margin-bottom: 25px;
	padding: 35px 25px 0 35px;
	position: relative; }

.j-page-block.success {
	background: #39b7a6;

	h1 {
		color: #fff!important; }

	label {
		color: #fff; }

	p {
		color: #fff!important; } }

.j-page-block.j-right-block {
	right: 0;
	width: 49%;
	float: right; }

.j-page-block.j-left-block {
	width: 49%;
	float: left; }

.j-row {
	word-spacing: -0.25em;
	text-align: left;
	margin-bottom: 17px;

	label {
		margin-bottom: 0;
		font-size: 12px;
		font-family: "RobotoLight",sans-serif;
		padding-left: 9px;
		word-spacing: 0em;

		span {
			display: inline-block;
			vertical-align: text-bottom; } }

	&:last-child {
		margin: 0; } }

.j-row-item {
	display: inline-block;
	vertical-align: top; }

.j-row-item-100 {
	width: 99%; }

.j-row-item-50 {
	width: 47%; }

.j-row-item-left {
	margin-right: 5%; }

.j-row-item-name {
	width: 40%;
	margin-right: 5%; }

.j-row-item-last-name {
	width: 55%; }

.j-input {
	border: 1px solid #a6abaa;
	width: 100%;
	padding: 2px 0 2px 9px;
	font-size: 20px; }

.j-select {
	border: 1px solid #a6abaa;
	padding: 4px 0 2px 4px;
	font-size: 20px; }

.j-select-year {
	width: 24%;
	margin-right: 5%; }

.j-select-month {
	width: 42%;
	margin-right: 5%; }

.j-select-day {
	width: 24%; }

.j-block-title {
	text-transform: uppercase;
	font-size: 24px;
	text-align: left;
	color: #39b7a6;
	margin-bottom: 25px;
	transition: color .3s ease; }

.j-title-orange {
	color: #ff5300; }

.j-block-about {
	padding-bottom: 45px; }

.j-block-contact {
	padding-bottom: 45px; }

.j-check {
	display: none; }

.j-payment-type-wrap {
	text-align: left;
	margin-bottom: 25px; }

.j-first-pay {
	padding-left: 0!important; }

input[type="radio"].j-check {
	+ label {
		display: inline-block;
		background: none;
		cursor: pointer;
		margin-bottom: 0;
		color: #000;
		line-height: 24px;
		font-size: 14px;
		font-family: "RobotoLight",sans-serif;
 } }		// transition: all 0.3s ease

input[type="radio"].j-check {
	+ label span {
		display: inline-block;
		width: 20px;
		height: 24px;
		margin-left: 0;
		vertical-align: middle;
		background: url(/assets/img/join-sprite.png) no-repeat -5px -93px;
		transition: none; } }

input[type="radio"].j-check:checked {
	+ label span {
		background-position: -5px -64px; } }

.j-pay-block {
	background: #fe4b00;
	padding-top: 10px;
	margin-bottom: 30px;
	box-shadow: -2px 2px 8px rgba(0,0,0,.35);

	h1 {
		font-size: 26px;
		margin-bottom: 25px;
		color: #fff; }

	label {
		color: rgba(255,255,255,.75);
		font-size: 14px;
		font-family: "RobotoLight",sans-serif; }

	.j-input {
		width: 65%;
		margin-bottom: 20px; } }

.j-block-agree {
	font-size: 21px;
	text-align: left;
	line-height: 25px;
	margin-bottom: 0;

	.music-agree {
		margin: 45px 0 30px; } }

.j-block-captcha {
	padding-bottom: 15px;
	margin-bottom: 0;
	position: relative; }

.j-layout {
	position: absolute;
	top: 0;
	bottom: -230px;
	background: rgba(255, 255, 255, 0.8);
	width: 100%;
	z-index: -1;
	left: 0; }

.j-step-1-btn {
	margin-top: 25px; }

.j-button_submit {
	margin-bottom: 70px;
	width: 345px;
	background: #ff5300;
	padding: 13px 0;
	font-size: 24px;
	text-transform: uppercase;

	&:hover {
		background: #d54b08; } }

.modal-bank-wrap {
	max-width: 1135px;
	width: 100%!important; }

.modal-bank-dialog {
	max-width: 1135px;
	width: 100%!important; }

.modal-bank-content {
	background: transparent!important; }

.j-modal-bank-block {
	width: 47%;
	display: inline-block;
	vertical-align: top;
	padding: 35px 25px 35px;
	background: rgba(255,255,255,.85);
	min-height: 590px;

	h2 {
		font-size: 19px;
		text-transform: uppercase;
		font-family: "RobotoBold",sans-serif;
		margin: 30px 0 20px;

		span {
			vertical-align: super; } } }

.j-modal-block-left {
	margin-right: 2%; }

.bank-modal-body {
	padding: 0!important; }

.j-error-line {
	height: 8px;
	position: absolute;
	width: 168px;
	background: #fb485e;
	top: 0;
	left: 50%;
	margin-left: -84px;
	display: none; }
