// PROFILE PAGE
.b-profile-block {
	overflow: visible; }

.b-profile-text {
	padding-left: 10px; }

.b-edit-block {
	padding: 0 0 20px 15px;
	word-spacing: -0.25em; }

.b-edit-block_about {
	border-bottom: 1px solid rgba(170,170,170,.2); }

.b-edit-block_contacts {
	padding-top: 20px;
	border-top: 1px solid rgba(0,0,0,.1); }

.b-edit__item {
	display: inline-block;
	vertical-align: top;
	margin: 19px 0 0 1.1%;
	word-spacing: 0; }

.b-item_no-margin {
	margin-left: 0; }

.b-edit-item_s {
	width: 24.13%; }

.b-edit-item_m {
	width: 49.36%; }

.b-edit-item_l {
	width: 100%; }

.b-edit-input-block, .b-edit-text-block {
	position: absolute;
	top: 6px;
	left: 0;
	width: 100%; }

.b-edit-input-block {
	top: -50px; }

.b-edit-wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 36px;
	margin-left: -10px; }

.b-page_edit-link {
	text-decoration: none;
	position: relative;

	span {
		text-decoration: underline; }

	.i-edit-invis {
		background-position: 0 -20px; }

	&:hover {
		.i-edit-invis {
			opacity: 1; } } }

.b-page_save-link {
	@extend .b-page_edit-link; }

.b-page_successd-link {
	text-decoration: none;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	display: none;
	transition: opacity .3s ease, visibility .3s ease;

	span {
		text-decoration: none; }

	.b-edit-ico {
		background: url('/assets/img/payoneer_status.png') no-repeat 0 0/100%;
		width: 15px;
		height: 15px;
		margin-top: 0; } }

.b-page_successd-link._show {
	opacity: 1;
	visibility: visible;
	width: 140px;
	text-align: left;
	top: 0;
	left: 0; }

.b-profile-settings {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	font: 300 16px "Roboto",sans-serif;
	text-decoration: none;
	padding: 20px 50px;
	background: url(/assets/img/settings.png) no-repeat 15px center #49bdae;
	color: #fff;
	transition: all 0.3s ease;

	&:hover {
		background-color: #259183;
		text-decoration: none;
		color: #fff; }
	&:active {
		text-decoration: none;
		color: #fff; }
	&:focus {
		text-decoration: none;
		color: #fff; } }

.b-payment-block {
	text-align: center;
	padding-bottom: 0; }

.b-payment-title {
	margin: 60px 0 10px; }

.b-payment-type-block {
	background: #fff;
	margin: 0 -65px 0 -65px;
	padding: 0 0 0;
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 0.5s ease;
	-moz-transition: max-height 0.5s ease;
	transition: max-height 0.5s ease;

	.b-page-title {
		margin-top: 20px; } }

.b-payment-type-block.open {
	max-height: 3000px; }

.b-select-payment-type {
	margin: 35px 0 0;
	padding-bottom: 25px; }

.payoneer-status {
	display: inline-block;
	padding-left: 40px;
	padding: 3px 0 1px 33px;
	background: url("/assets/img/payoneer_status.png")no-repeat; }

.js-payoneer-status-info {
	background: none; }

.b-Approved {
	background-position: left 0; }

.b-Denied {
	background-position: left -47px; }

.b-Pending {
	background-position: left bottom; }

input[type="radio"] {
	+ label {
		display: block;
		background: #edf0f1;
		text-align: right;
		padding: 0 0 0 20px;
		font-size: 18px;
		font-weight: 500;
		line-height: 45px;
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			background: #dadfe1;
			color: #000;

			span {
				background-position: 0 -50px; } } } }

input[type="radio"]:checked {
	+ label {
		background: #fe4b00;
		color: #fff; } }

input[type="radio"] {
	+ label span {
		display: inline-block;
		width: 50px;
		height: 45px;
		margin-left: 20px;
		vertical-align: middle;
		background: url(/assets/img/check.png) no-repeat 0 -100px;
		transition: all 0.3s ease; } }


input[type="radio"]:checked {
	+ label span {
		background-position: 0 0; } }

.b-payment-type-element {
	display: inline-block;
	vertical-align: top;
	margin-right: 51px;

	&:last-child {
		margin-right: 0; } }

.b-payment-type-form {
	background: #3ab8a7;
	padding: 0 60px 0;
	text-align: center;
	display: block;
	max-height: 0;
	transition: max-height 0.3s ease;

	span {
		color: rgba(255,255,255,.50); }

	.b-page-label {
		color: rgba(255,255,255,.50)!important; } }

.b-payment-type-form._show {
	max-height: 3000px;
	padding: 30px 60px 15px; }

.b-payment-form {
	border-bottom: 1px solid #43a89c;
	border-top: 1px solid #56bbaf;
	padding: 20px 0;

	&:first-of-type {
		border-top: none; }

	.b-page-input {
		padding-left: 20px; } }

.b-payment-form_bot {
	border-bottom: none; }

.b-payment-row {
	text-align: left;
	word-spacing: -0.25em;

	.b-page-label {
		color: #fff;
		margin-left: 20px;
		margin-bottom: 0; } }

.b-payment-row__item {
	margin-bottom: 20px;
	display: inline-block;
	vertical-align: top;
	width: 49%;
	word-spacing: 0;
	margin-left: 2%;
	position: relative;
	// overflow: hidden

	&:first-child {
		margin-left: 0; }

	.b-input-error {
		background: #fff; } }

.b-notice-block {
	padding: 8px 0 80px;
	background: #fe4b00;
	margin: 0 -65px 0;
	color: #fff;

	p {
		width: 72%;
		margin: 0 auto;
		text-align: left; } }

.b-title-notice {
	margin: 18px 0;
	color: #fff; }

.b-settings-row {
	border-top: 1px solid rgba(255,255,255,.1);
	border-bottom: 1px solid rgba(255,255,255,.1);
	margin-top: -1px;
	transition: all 0.3s ease;

	&:hover {
		background: #fff;
		border-top: 1px solid #cedbda;
		border-bottom: 1px solid #cedbda;
		box-shadow: 0 2px 0px -1px rgba(241, 241, 241, 0.9) inset, 0 1px 0px 0px rgba(224, 237, 236, 0.9); }

	&:last-child {
		.b-settings-row__item {
			border-bottom: none; } } }

.b-settings-row__item {
	padding: 5px 0 5px;
	margin: 0 45px;
	text-align: right;
	border-bottom: 1px solid #cedbda;
	margin-bottom: -1px;
	cursor: pointer; }

.b-settings-title {
	display: inline-block;
	vertical-align: middle;
	width: 82%;
	text-align: left;
	float: left;
	line-height: 64px;
	padding-left: 15px; }

input[type="checkbox"].b-settings-check {
	+ label {
		float: left;
		line-height: 59px; } }

.b-pass-access-form {
	p {
		padding-left: 15px; } }


.b-payoneer-agree {
	text-align: left;
	color: #fff; }

.b-payoneer_content {
	display: none; }

.b-payoneer-iframe {
	display: none; }

.b-payoneer-accept {
	color: #fff; }

.b-payoneer-wrap {
	background: #fff;
	margin: -30px -60px -15px;
	padding: 30px 60px 15px; }

.js-payoneer-status-info {
	word-spacing: 0;
	color: #000;
	padding: 5px 0 5px 33px;
	text-align: center;

	a {
		margin: 0; } }

.b-unique-codes {
	margin: 20px 0 5px 0; }

.b-unique-list {
	margin: 0; }

.b-unique-item {
	display: inline-block;
	padding-right: 40px; }

.b-unique-number {
	color: #27b29f!important;
	font-family: "RobotoBold",sans-serif; }

.b-agree-form {
	p {
		color: #000; } }

.b-pass-access-ok {
	background: #fe4b00;

	&:hover {
		background: #d54b08; } }

.b-pass-access-hide {
	@extend .b-pass-access-ok;
	display: none; }

.b-pass-access-title {
	display: none; }

.b-pass-access-dis {
	display: none; }

.b-pass-access-act {
	display: none; }

.b-bank-error-box {
	width: 100%;
	color: #940707;
	font-family: "RobotoLight",sans-serif; }

.b-bank-error-field {
	line-height: 34px;
	font-size: 20px;
	padding-left: 20px;
	padding-top: 1px;
	position: absolute;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease, visibility .3s ease;

	span {
		font-size: 12px;
		float: right;
		margin-right: 8px;
		color: #940707; } }

.b-bank-error-field._show {
	opacity: 1;
	visibility: visible; }

.b-bank-error-val {
	max-width: 70%;
	float: left; }

.b-bank-error-desc {
	display: none;
	padding: 20px 20px 10px 20px;
	font-size: 12px;
	background: #ffa4af;
	position: relative;
	overflow: hidden; }

.b-bank-error-btn {
	width: 34px;
	height: 35px;
	float: right;
	margin-top: -1px;
	background: url(/assets/img/error-ico.png)no-repeat 0 0px;
	cursor: pointer; }

.b-bank-error-tooltip {
	position: absolute;
	bottom: -3px;
	width: 15px;
	height: 10px;
	background: #BD0000;
	right: -5px;
	transform: rotate(-45deg);
	display: none; }

.b-bank-label {
	margin-left: 3px!important; }

.b-bank-tooltip {
	margin-left: 0; }

.js-set-profile-password[disabled] {
	background: #fe4b00;

	&:hover {
		background: #d54b08; } }

.additional-success {
	width: 27px;
	height: 27px;
	background: url(/assets/img/payoneer_status.png)no-repeat 0 0;
	vertical-align: middle!important;
	float: left;
	margin-top: 16px; }

.b-access-row {
	&:hover {
		.b-tooltip {
			&:after {
				opacity: 1;
				visibility: visible; }

			&:before {
				opacity: 1;
				visibility: visible; } } } }


.js-pay-success-link._show {
	left: 50%;
	margin-left: -70px;
	text-align: center; }

.b-profile-edit-link {
	margin-top: 20px;

	button {
		margin-top: 0!important; } }
