.modal-open {
	overflow: hidden!important; }

.modal {
	z-index: 99999; }

.modal-wrap {
	width: 850px;
	margin: 0 auto; }

.modal-backdrop {
	z-index: 9999;
	background: -moz-linear-gradient(bottom left, #76bfcc 0%, #83cfcc 46%, #88d6cc 51%, #95e7cb 100%); }

.modal.in {
	.modal-dialog {
		transform: translate(0, 70px); } }

.modal-header {
	border: none;
	text-align: center;
	position: absolute;
	width: 100%;
	top: -60px;
	background: rgba(255,255,255,1);
	border-radius: 10px 10px 0px 0px; }

.modal-body {
	padding: 60px 20px;
	border-radius: 0px 0px 10px 10px; }

.modal-title {
	font: 500 26px "RobotoMedium",sans-serif;
	text-transform: uppercase; }

.modal-dialog {
	width: 850px;
	transition: all 0.3s all; }

.modal-sm {
	width: 100%; }

.modal-content {
	border: none;
	box-shadow: none;
	background: rgba(255,255,255,1);
	border-radius: 0px 0px 10px 10px; }

.modal-backdrop {
	background: -webkit-linear-gradient(bottom left, #76bfcc 0%,#83cfcc 46%,#88d6cc 51%,#95e7cb 100%);
	background: -o-linear-gradient(bottom left, #76bfcc 0%,#83cfcc 46%,#88d6cc 51%,#95e7cb 100%);
	background: -ms-linear-gradient(bottom left, #76bfcc 0%,#83cfcc 46%,#88d6cc 51%,#95e7cb 100%);
	background: linear-gradient(bottom left, #76bfcc 0%,#83cfcc 46%,#88d6cc 51%,#95e7cb 100%); }

.modal-backdrop.in {
	opacity: 0.8; }

.modal-header .close {
	margin-right: 10px; }

.close {
	opacity: 1;
	position: relative;
	right: -15px;
	top: 6px;

	&:hover {
		opacity: 1;

		span {
			background-position: 0 -40px; } }

	span {
		background: url(/assets/img/close.png) no-repeat 0 0;
		display: block;
		width: 31px;
		height: 31px;
		position: relative; } }
