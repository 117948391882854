.b-music-page-block {
	padding-top: 0;
	background: transparent;
	min-width: 1070px;
	margin: 0 auto 110px!important;
	overflow: visible; }

.b-tabs-active {
	.i-edit-invis {
		opacity: 1;
		visibility: visible; } }

.b-tabs-selected {
	position: relative;

	&:hover {
		.i-edit-invis {
			opacity: 1;
			visibility: visible; } }

	.i-edit {
		background: url(/assets/img/music-star.png)no-repeat -7px -65px;
		width: 14px;
		height: 13px; }

	.i-edit-invis {
		background-position: -7px -81px;
		right: 0;
		left: inherit; } }

.b-music-actions {
	text-align: center;
	background: #fff;
	padding: 25px 65px;
	margin: 0 -65px;
	position: relative;

	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: -23px;
		left: 50%;
		width: 10px;
		margin-left: -12px;
		border: 10px solid transparent;
		border-top: 15px solid #fff; } }

.b-music-header {
	margin: 35px -65px -5px -65px;
	position: relative;
	text-align: center; }

nav.breadcrumbs {
	position: absolute;
	top: 3px;
	left: 0; }

.b-crumbs-item {
	font-size: 12px;
	color: #3bb9a8;
	text-decoration: none;
	transition: all 0.3s ease;

	&:hover {
		color: #309688;
		text-decoration: none; } }

.b-crumbs-current {
	color: #000;
	cursor: default;

	&:hover {
		color: #000; } }

.js-music-paginator-region {
	position: absolute;
	top: 165px;
	right: 0; }

.b-pagination {
	word-spacing: -0.25em; }

.b-pagination-item {
	display: inline-block; }

.b-change-page-link {
	text-decoration: none;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	padding: 4px 7px 3px;
	display: block;
	line-height: 12px;
	transition: all 0.3s ease;

	&:hover {
		text-decoration: none;
		background: #A2A1A1; } }

.b-change-page-link.active {
	background: #3bb9a8;
	color: #fff; }

.b-change-page-link.disabled {
	&:hover {
		background: none; } }

.b-genre-title {
	margin-bottom: -5px; }

.b-music-list {
	margin: 20px -65px 0; }

.b-music-row {
	word-spacing: -0.25em;
	background: #fff;
	transition: all 0.3s ease;
	margin-bottom: 10px;
	border: 1px solid #fff;
	transition: border 0.3s ease;

	&:hover {
		border: 1px solid #babec1; } }

.b-music-row.play {

	background: #3bb9a8;
	border: 1px solid #3bb9a8;

	span {
		color: #fff; }

	p {
		color: #fff; }

	label {
		color: #fff; }

	.i-edit-play {
		opacity: 0; }

	.i-edit-play-invis {
		opacity: 1; }

	.b-music-download {
		color: #000;
		background: #fff;
		background: url(/assets/img/music_dwnld.png)no-repeat 7px 14px #fff; }

	.b-page-link {
		color: #fff;

		&:hover {
			color: #fff!important; } } }

.b-music-item {
	padding: 25px 0 15px;
	word-spacing: 0;
	display: inline-block;
	vertical-align: top;
	min-height: 135px;
	transition: all 0.3s ease;

	label {
		transition: all 0.3s ease; }

	p {
		transition: max-height 0.3s ease; }

	span {
		transition: all 0.3s ease; } }

.b-music-left {
	width: 30%;
	min-width: 319px; }

.b-play {
	width: 66px;
	height: 66px;
	vertical-align: top;
	position: relative;
	margin: 10px 35px 0 25px;
	cursor: pointer;

	.i-edit {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(/assets/img/play_sprite.png)no-repeat 0 0;
		transition: opacity 0.3s ease; } }

.b-play.pause {
	.i-edit {
		background-position: 0 -160px; } }

.b-play.play {
	.i-edit {
		background-position: 0 -80px; } }

.i-edit-invis {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	background-position: 0 -79px;
	transition: all 0.3s ease; }

.b-music-name {
	width: 185px;

	span {
		transition: all 0.3s ease; } }

.b-music-titles {
	font-size: 24px;
	color: #3bb9a8;
	line-height: 28px; }

.b-music-artist {
	font-size: 14px;
	color: #9ba5aa; }

.b-music-center {
	width: 53%;
	padding-left: 25px;
	font-family: "Roboto Regular", sans-serif;
	border-left: 1px solid rgba(0,0,0,.2);
	border-right: 1px solid rgba(191,191,191,.3);
	box-shadow: -1px 0 0 rgba(191, 191, 191, 0.3), 1px 0 0 rgba(0, 0, 0, 0.2);

	label {
		color: #9ba5aa;
		font-weight: normal;
		vertical-align: top; } }

.b-music-tags {
	width: 85%;
	font-family: "Roboto Regular", sans-serif;
	display: inline-block;
	line-height: 15px;
	margin-top: 2px;
	margin-bottom: 3px;
	max-height: 16px;
	overflow: hidden;
	position: relative; }

.b-music-tags._show {
	max-height: 3000px;
	transition: max-height 2.3s ease;

	.b-tags-dots {
		opacity: 0!important;
		visibility: hidden!important; } }

.b-music-tags.b-add-dots {
	.b-tags-dots {
		opacity: 1;
		visibility: visible; } }

.b-tags-dots {
	position: absolute;
	right: 0;
	top: 0px;
	display: block;
	width: 51px;
	background: #fff;
	opacity: 0;
	visibility: hidden;
	transition: visibility .1s ease, opacity .1s ease; }

.b-music-right {
	width: 17%;
	text-align: center;
	position: relative; }

.b-music-download {
	color: #fff;
	padding: 10px 20px 10px 25px;
	background: url(/assets/img/music_dwnld.png)no-repeat 7px -47px #3bb9a8;
	margin-top: 20px;
	transition: all 0.3s ease;

	&:hover {
		background-color: #259183; } }

.b-music-star {
	position: absolute;
	width: 27px;
	height: 25px;
	top: 5px;
	right: 5px;
	background: transparent;

	.i-edit {
		width: 100%;
		height: 100%;
		background: url(/assets/img/music-star.png) no-repeat 0 0; }

	.i-edit-invis {
		background-position: 0 -31px; }

	&:hover {
		.i-edit-invis {
			opacity: 1; } } }

.b-music-star.selected {
	.i-edit-invis {
		opacity: 1; } }

.b-music-tags {
	label {
		margin-bottom: 0; } }

.b-tags-show {
	margin-top: 0!important; }

// SELECT CUSTOMISATION
.chosen-container {
	width: 195px!important; }

.chosen-search {
	display: none!important; }

.chosen-single {
	border: none!important;
	box-shadow: none!important; }

.select-preload {
	+ .chosen-container {
		b {
			top: 9px!important;
			left: -18px!important;
			width: 20px!important;
			height: 20px!important;
			background: url(/assets/img/spinner_small.GIF)no-repeat 0 0;
			border: none!important; } } }

// MODAL

.b-music-modal-wrap {
	max-height: 350px;
	overflow-y: scroll; }

.next-cat {
	font-size: 16px; }

.modal-body.music-modal-body {
	padding: 35px 0 0; }

.b-music-title {
	font-size: 24px;
	text-transform: uppercase;
	text-align: center;
	border-bottom: 1px solid #cedbd9;
	box-shadow: 0 1px 0 #e0eeec;
	padding-bottom: 10px;
	color: #27b29f;
	letter-spacing: 1px; }

.b-music-modal-content {
	padding: 10px 65px;

	.b-page-link {
		margin: 0; } }

.b-music-modal-footer {
	border-top: 1px solid #eaf1f1;
	box-shadow: 0 -1px 0 #d7ddde;
	padding: 20px 30px 35px;
	background: #fff;

	label {
		color: #9da0a1;
		margin: 10px auto 0; }

	.b-music-download {
		margin: -9px 0 0;
		background-color: #9ba5aa;

		&:hover {
			background-color: #7c868b; } }

	.b-music-download.active {
		background-color: #3bb9a8;

		&:hover {
			background-color: #259183; } }

	.b-page-input {
		width: 74%;
		font: 15px "RobotoRegular",sans-serif;
		padding: 5px 0 5px 15px;
		height: 36px; } }

.nicescroll-rails {
	top: 170.5px !important;
	background: #e4f1ed;
	opacity: 1!important;
	right: 0!important; }

.nicescroll-cursors {
	width: 10px!important;
	background: rgb(200, 214, 212)!important;
	border: none!important;
	border-radius: 0!important;
	right: -2px; }

.music-agree {
	width: 161px;
	margin: 0 auto; }

.b-music-terms {
	text-align: left;
	padding: 0 4.5%; }

.count-terms {
	display: inline-block;
	color: #27b29f;
	font-style: italic;
	font-family: "RobotoMedium",sans-serif;
	margin-left: -21px;
	vertical-align: top; }

.b-terms-title {
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: "RobotoMedium",sans-serif;
	margin-top: 20px; }

.you-may {
	text-align: center;

	span {
		display: inline-block;
		text-transform: uppercase;
		padding: 6px 8px 4px;
		background: #27b29f;
		color: #fff;
		font-family: "RobotoMedium",sans-serif; } }

.you-may-not {
	span {
		background: #fb4000;
		padding: 6px 10px 4px; } }

.b-terms-bold {
	font-family: "RobotoBold",sans-serif;
	font-size: 18px;
	width: 396px;
	margin: 10px auto;
	text-align: center;

	a {
		margin: 0;
		font: 18px "RobotoBold",sans-serif; } }

.b-terms-row {
	display: block;
	width: 180px;
	height: 2px;
	background: #d9dcde;
	border-bottom: 1px solid #eef0f1;
	margin: 25px auto 20px; }

.b-terms-row-wide {
	width: 140%;
	margin: 30px 0 30px -140px; }

.js-music-terms {
	z-index: 999;
	position: relative;
	margin: -50px -65px 0;
	overflow: hidden; }

.b-music-terms {
	text-align: left;
	padding: 0 110px 20px;
	background: #fff; }

.b-music-terms-modal._hide {
	display: none; }

.b-music-notice-modal {
	opacity: 0;
	visibility: hidden;
	transition: visibility .3s ease, opacity .3s ease;
	position: absolute;
	top: 0;
	background: #f65a15;
	color: #fff;
	padding: 70px 160px 0;
	text-align: center;

	.b-link {
		color: rgba(255,255,255,.75);

		&:hover {
			color: rgba(255,255,255,1)!important; } } }

.b-music-notice-modal._show {
	opacity: 1;
	visibility: visible; }

.b-music-cat {
	font-size: 12px;
	color: #3bb9a8;
	font-family: "RobotoMedium",sans-serif;
	cursor: pointer; }

.b-music-cat.disable-crumb {
	color: #000;
	cursor: default; }

.b-breadcrumbs-wrap {
	position: absolute;
	left: 0; }

.b-breadcrumbs-top {
	top: 192px; }

.b-breadcrumbs-bottom {
	bottom: -65px; }
