// PAYOUT PAGE

.b-payout-page-block {
	background: transparent;
	padding: 0; }

.b-advanced-link {
	position: absolute;
	top: -12px;
	right: 20px;
	z-index: 99;
	padding: 4px 0 4px 28px;
	background: url(/assets/img/plus.png)no-repeat 0; }

.b-slide-wrap {
	background: #fff;
	position: absolute;
	top: 2px;
	width: 100%;
	left: 0;
	border-bottom: 1px solid #d7dbdc; }

.b-slick-year {
	top: 0px!important; }

.b-slide-year-wrap {
	background: none;
	top: 39px;
	padding-bottom: 1px;
	border-bottom: none;
	box-shadow: none;

	.slick-slide {
		color: #fff;
		padding: 10px 0;

		&:hover {
			color: #fff;
			background: rgba(0,0,0,.2); } }

	.slick-current {
		color: #fff;
		background: rgba(0,0,0,.2); }

	.b-slick-center {
		padding: 0; } }

.b-slick-year {
	.slick-slide {
		width: 86px; } }

.b-payout-header {
	text-align: center;
	padding-top: 25px;
	background: #27b29f;
	margin: 0 -65px; }

.b-pay-title {
	margin: 5px 0 10px; }

.b-payout-header {

	.b-sum-block {
		margin: 0 0 10px;
		width: 100%;
		display: inline-block; } }

.b-sum-block {
	background: #27b29f;
	color: #fff;
	padding: 5px 15px;
	word-spacing: -0.25em;
	text-align: center;
	margin: 0 -65px; }

.b-sum-block__item {
	display: inline-block;
	vertical-align: top;
	padding: 15px 25px;
	min-width: 222px;
	border-right: 2px solid #47b6a8;
	border-left: 1px solid #34a395;

	&:first-child {
		padding-left: 0;
		border-left: none; }

	&:last-child {
		padding-right: 0;
		border-right: none; } }

.b-sum-count {
	font: 50px "RobotoBold",sans-serif;
	display: inline-block;
	vertical-align: middle; }

.b-sum-title {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin-left: 5px; }

.b-sum-desc {
	vertical-align: middle;
	color: #27b29f; }

.b-payout-body {
	margin: 45px -65px 30px;
	padding: 0 65px;
	position: relative; }

.b-payout-body.b-show-count {
	.b-bottom-layer {
		top: 62px; } }

.b-bottom-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 45px;
	background: #fff;
	z-index: -1; }

.b-payout-count-up {
	margin-top: -14px; }

.b-payout-count-down {
	margin-top: 75px;
	margin-bottom: -12px;
	padding: 0 65px 8px; }

// TABLE

// THEAD
th.b-table-earning {
	text-align: left; }

.b-rejected-row {
	color: #d80000;

	td {
		color: #d80000; }

	span {
		color: #d80000; }

	a {
		color: #d80000; }

	.b-table-earning {
		color: #d80000; } }

.b-table-pay {
	color: #27b29f;
	font-family: "RobotoBold",sans-serif;
	font-size: 12.28px; }

.b-table-reason {
	color: #d80000; }

.b-table-earning {
	color: #fb4000;
	text-align: center; }

.b-table-earning__item {
	display: inline-block;
	vertical-align: top;
	margin-top: 2px;
	text-align: right;
	min-width: 30px; }

.b-download-link {
	display: inline-block;
	width: 20px;
	height: 21px;
	border-radius: 100%;
	vertical-align: top;
	position: relative;

	&:hover {
		.i-edit_invis {
			opacity: 1;
			visibility: visible; } }

	.i-edit {
		width: 100%;
		height: 100%;
		background: url(/assets/img/payout_sprite.png)no-repeat 0 1px; }

	.i-edit_invis {
		background-position: -24px 1px; }

	&:hover {
		&:after {
			opacity: 1;
			visibility: visible; }

		&:before {
			opacity: 1;
			visibility: visible; } }

	&:after {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		content: attr(data-desc);
		display: block;
		min-width: 80px;
		min-height: 10px;
		background: #cfd7db;
		color: #525557;
		white-space: nowrap;
		padding: 2px 7px;
		top: -31px;
		left: -5px;
		transition: all 0.3s ease;
		text-indent: 0; }

	&:before {
		opacity: 0;
		visibility: hidden;
		content: "";
		position: absolute;
		top: -10px;
		left: 4px;
		border: 5px solid transparent;
		border-top: 10px solid #cfd7db;
		transition: all 0.3s ease; } }

.b-download-link.right {
	&:after {
		right: -5px;
		left: inherit; }

	&:before {
		left: 7px; } }

.b-download-link_disabled {
	.i-edit {
		width: 100%;
		height: 100%;
		background: url(/assets/img/payout_sprite.png)no-repeat -76px 0; }

	.i-edit_invis {
		background-position: -76px 0; } }

.b-pament-status {
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 25px;
	// text-indent: -9999px
	background: url(/assets/img/payout_sprite.png)no-repeat; }

.b-status-pending {
	background-position: 0 -24px; }

.b-status-ok {
	background-position: -30px -24px; }

.b-status-rejected {
	background-position: 0 -53px; }

.payout-table {
	th {
		&:nth-child(2) {
			padding-left: 0; }

		&:nth-child(3) {
			text-align: center;
			padding-left: 23px; }

		&:nth-child(7) {
			text-align: left; }

		&:nth-child(9) {
			text-align: center; }

		&:nth-child(8) {
			text-align: center; } }
	td {
		&:nth-child(1) {
			width: 14%; }

		&:nth-child(2) {
			width: 12%;
			padding-left: 0; }

		&:nth-child(3) {
			width: 6%;
			text-align: right;
			padding-left: 23px;
			padding-right: 5px;
			white-space: nowrap; }

		&:nth-child(4) {
			width: 16%; }

		&:nth-child(5) {
			width: 9%; }

		&:nth-child(6) {
			width: 18%; }

		&:nth-child(7) {
			text-align: left; }

		&:nth-child(8) {
			text-align: center; } }

	.b-pament-status {
		text-indent: 99999px; }

	.b-channel-name {
		a {
			span {
				max-width: 120px; } } } }
// TABS
.b-payout-page-tabs {
	margin: 0 -65px;
	background: #fff;
	text-align: center;
	box-shadow: 0 1px 0 0 rgb(199,205,207); }

.b-payout-tabs-item {
	display: inline-block;
	padding: 18px 25px 4px;
	cursor: pointer;
	position: relative;

	&:hover {
		.b-payout-tabs-title {
			color: #27b29f;
			border-bottom: 3px solid #27b29f; }

		.b-tabs_hover__item {
			opacity: 1;
			visibility: visible; } }

	.b-payout-tabs-title {
		padding-bottom: 3px;
		border-bottom: 3px solid transparent;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: all 0.3s ease; } }

.b-tabs_hover__item {
	opacity: 0;
	visibility: visible;
	position: absolute;
	transition: all 0.3s ease;
	background: #fff;
	white-space: nowrap;
	z-index: 9;
	left: 5px;
	bottom: -38px;
	padding: 7px 20px; }

.b-tabs-active {
	.b-payout-tabs-title {
		color: #27b29f;
		border-bottom: 3px solid #27b29f; } }
// TABS END

.js-toolbar-year {
	position: relative;
	top: 0; }

.js-toolbar-month {
	position: relative;
	top: -26px; }

.b-payout-table-block {
	margin: 45px 0 30px; }

.b-payout-adv-title {
	margin: 0!important; }

.js-payout-advanced-toolbar {
	margin: 0!important; }

.js-reset-payout-filter {
	position: absolute;
	left: 34px;
	font-size: 12px!important;
	top: -2px; }
