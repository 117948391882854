@import "../vendor/bootstrap-sass/assets/stylesheets/_bootstrap"
@import "./blocks/header/_header"
@import "./blocks/dashboard/_dashboard"
@import "./blocks/dashboardLogin/_dashboardLogin"
@import "./blocks/footer/_footer"
@import "./blocks/home/_home"
@import "./blocks/main/_main"
@import "./blocks/profile/_profile"
@import "./blocks/payout/_payout"
@import "./blocks/channels/_channels"
@import "./blocks/music/_music"
@import "./blocks/inbox/_inbox"
@import "./blocks/cpm/_cpm"
@import "./blocks/support/_support"
@import "./blocks/join/_join"
@import "./blocks/advancedreport/_advancedreports"
@import "./helpers/_variables"
@import "./helpers/_reset"
@import "./helpers/_fonts"
@import "./helpers/_modal"
@import "./helpers/_slick"
@import "./mixins/_flexbox"
@import "./helpers/_media_1440"
@import "./helpers/_chosen"
@import "./ovveride/_dataTables"

html,body
	min-height: 100%
	height: 100%

body
	background: url($backgroud_image_url) 0 0/cover
	font-family: "RobotoRegular"
	font-size: 14px

.clearfix::after
	display: block
	content: ''
	clear: both

.container-fluid 
	height: 100%

.wrapper_n
	padding: 56px 0 0 0

.i-edit 
	display: inline-block
	vertical-align: top
	
