.b-advanced-page-block {
	background: transparent!important;
	padding-top: 0; }

.b-reports-sum {
	padding: 15px;
	border-top: 1px solid #47b6a8; }

.b-reports-settings {
	border-bottom: 1px solid #34a395; }

.b-page-link.b-change-search {
	color: #3bb9a8;
	top: 18px;
	right: 66px; }

.b-reports-set-open {
	padding: 0;
	margin: 0 -65px 0;
	background: #aedbd5;
	max-height: 0;
	display: block!important;
	transition: max-height 0.3s ease;
	border-bottom: none;
	overflow: hidden;

	.search-cancel {
		right: 65px; } }

.b-reports-set-open.open {
	max-height: 200px;
	overflow: visible;
	border-bottom: 4px solid #fff;
	border-top: 1px solid #f1f3f4;
	transition: max-height 0.1s ease;

	.b-settings-wrap {
		opacity: 1; } }

.channel-sel-wrap {
	width: 30%;

	select {
		width: 100%; } }

.b-settings-wrap {
	padding: 25px 65px 40px;
	opacity: 0;
	transition: all 0.5s ease; }

.dropdown-begin-period {
	position: relative;
	top: -1px;

	.b-drop-ico {
		margin-top: 6px;
		float: right; } }

.dropdown-btn {
	height: 34px;
	padding: 0 10px 0 15px;
	background: #fff!important;
	color: #7f7f7f;
	width: 160px!important;

	.b-drop-ico {
		background-position: -6px -261px; } }

.dropdown-list {
	position: absolute;
	background: #f6f7f8;
	border: 1px solid #adadad;
	width: 100%;
	min-width: initial!important;
	border-radius: 0;
	right: -1px;
	z-index: 999;
	transition: all 0.3s ease;
	box-sizing: content-box;

	&:after {
		content: "";
		position: absolute;
		border: 6px solid transparent;
		border-bottom: 9px solid #fff;
		top: -15px;
		right: 9px; }

	&:before {
		content: "";
		position: absolute;
		border: 7px solid transparent;
		border-bottom: 10px solid #adadad;
		top: -17px;
		right: 8px; }

	.slick-current {
		border: 0;
		color: #545555; }

	.slick-slide {
		margin: 0;
		border-bottom: 0;
		padding-bottom: 4px;

		&:hover {
			color: #545555;
			border-bottom: 0; } }

	.slick-next {
		right: -6px;
		top: 14px;
		left: inherit!important;
		background-position: -9px -167px!important; }

	.slick-prev {
		left: -3px;
		top: 14px;
		background-position: 10px -167px;
		z-index: 9999; } }

.dropdown-list._show {
		opacity: 1;
		visibility: visible; }

.dropdown-item {
	padding: 5px;
	text-align: center;
	color: #545555;

	&:hover {
		background: #dbe1e3; } }

.dropdown-period {
	&:hover {
		background: #dbe1e3; } }

.b-advanced-page-block {
	.b-channels-layer {
		top: 45px; } }

.js-advance-report-set-filter:disabled {
	background: #BFBFBF!important; }

.js-advance-report-diagram {
	padding: 0!important;
	background: #fff; }

.highcharts-legend-item {
	text {
		color: #9e9f9f!important;
		fill: #9e9f9f!important;
		font-family: "RobotoRegular",sans-serif!important;
		font-size: 12px!important;
		font-weight: normal!important; } }

.highcharts-axis-labels.highcharts-xaxis-labels {
	text {
		color: #000!important;
		fill: #000!important;
		font-family: "RobotoRegular",sans-serif!important;
		// font-size: 17px!important
		font-weight: normal!important; } }

.highcharts-axis {
	text {
		tspan {
			color: #000!important;
			fill: #000!important;
			font-family: "RobotoRegular",sans-serif!important;
			font-size: 17px!important;
			font-weight: normal!important; } } }

.highcharts-axis-labels.highcharts-yaxis-labels {
	text {
		font-size: 12px !important;
		font-family: "RobotoRegular",sans-serif!important;
		color: #9d9f9f!important;
		fill: #9d9f9f!important; } }

.advanced-table {
	th {
		&:nth-child(3) {
			text-align: center; }
		&:nth-child(4) {
			text-align: right;
			padding-right: 2%;
			width: 11%!important; }
		&:nth-child(7) {
			text-align: center; } }
	td {
		&:nth-child(1) {
			width: 11%; }
		&:nth-child(2) {
			width: 12%; }
		&:nth-child(3) {
			width: 14%;
			text-align: center; }
		&:nth-child(4) {
			text-align: right;
			padding-right: 2%; }
		&:nth-child(5) {
			width: 15%;
			text-align: left; }
		&:nth-child(6) {
			width: 16%;
			text-align: left; } } }

.b-advanced-count-up {
	margin-top: 30px;
	margin-bottom: -30px; }

.b-advanced-count-down {
	margin-top: 60px;
	margin-bottom: -10px; }

.highcharts-container {
	overflow: visible!important; }
