.b-cpm-page-block {
	background: transparent;
	padding-top: 0; }


.b-advanced-title {
	position: relative;
	text-align: center;
	background: #fff;
	margin: 0 -65px;
	padding: 35px 65px 28px;
	border-bottom: 1px solid #dee0e1; }

.back {
	position: absolute;
	top: 38px;
	left: 65px;
	margin: 0; }

.cpm-download {
	background-position: -76px -51px!important; }

.cpm-link {
	float: left;

	&:after {
		font-size: 10px;
		font-family: "RobotoRegular",sans-serif;
		top: -28px; } }

.select-title {
	font-size: 18px;
	display: inline-block;
	color: #fff; }

.b-cpm-select {
	margin-right: 12px;
	vertical-align: bottom;

	select {
		margin-right: 0; } }

.b-advanced-settings {
	margin: 0 -65px;
	padding: 0 65px;
	background: #27b29f;
	border-top: 1px solid #f1f3f4; }

.b-settings-close {
	text-align: center;
	position: relative;
	padding: 11px 0;
	opacity: 1;
	transition: opacity 0.3s ease; }

.b-change-search {
	position: absolute;
	top: 11px;
	right: 0;
	background: transparent;
	text-decoration: underline;
	color: #000; }

.b-settings-open {
	position: relative;
	display: none;
	padding: 25px 65px 40px;
	background: #aedbd5;
	margin: 0 -65px;
	border-bottom: 5px solid #fff;

	.b-page-button {
		margin: 0;
		width: 138px;
		vertical-align: bottom;
		padding: 4px 0;
		font-size: 20px; }

	label {
		color: #445754!important;
		margin-left: 15px; }

	.b-change-search {
		top: 54px;
		right: 65px; } }

.b-channel-select {
	width: 385px;
	padding-left: 10px; }

.b-cpm-page-block {

	.b-channels-layer {
		top: 45px; }

	tr {
		&:first-child {
			td {
				padding-top: 10px; } } }

	th {
		text-align: left; }

	td {
		text-align: left; }

	.dataTables_length {
		display: none; }

	.dataTables_paginate {
		display: none; } }

.general-table {
	th {
		&:nth-child(2) {
			text-align: right; }

		&:nth-child(3) {
			text-align: right; }

		&:nth-child(4) {
			text-align: right; }

		&:nth-child(5) {
			text-align: right; }

		&:nth-child(6) {
			padding-left: 7%; } }
	td {
		&:nth-child(1) {
			width: 11%; }

		&:nth-child(2) {
			text-align: right;
			width: 1%; }

		&:nth-child(3) {
			text-align: right;
			width: 11%; }

		&:nth-child(4) {
			text-align: right;
			width: 10%; }

		&:nth-child(5) {
			text-align: right;
			width: 9%; }

		&:nth-child(6) {
			text-align: right;
			width: 1%;
			padding-right: 1%; } } }

.ads-table {
	th {
		&:nth-child(2) {
			text-align: right; }

		&:nth-child(3) {
			text-align: right; }

		&:nth-child(4) {
			text-align: right;
			padding-right: 1%; } }

	td {
		&:nth-child(1) {
			width: 8%; }

		&:nth-child(2) {
			text-align: right;
			width: 1%; }

		&:nth-child(3) {
			text-align: right;
			width: 11%; }

		&:nth-child(4) {
			text-align: right;
			width: 10%;
			padding-right: 1%; } } }

.country_name {
	span {
		span {
			max-width: 140px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline-block;
			line-height: 10px;
			margin-bottom: -2px; } } }
