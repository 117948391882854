@font-face {
    font-family: "Roboto Condensed";
    src: url("../../assets/fonts/RobotoCond.eot");
    src: url("../../assets/fonts/RobotoCond.eot?#iefix")format("embedded-opentype"),
    url("../../assets/fonts/RobotoCond.woff") format("woff"),
    url("../../assets/fonts/RobotoCond.ttf") format("truetype") {}
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "Roboto Condensed Light";
    src: url("../../assets/fonts/RobotoCondLight.eot");
    src: url("../../assets/fonts/RobotoCondLight.eot?#iefix")format("embedded-opentype"),
    url("../../assets/fonts/RobotoCondLight.woff") format("woff"),
    url("../../assets/fonts/RobotoCondLight.ttf") format("truetype") {}
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "RobotoRegular";
    src: url("../../assets/fonts/RobotoR.eot");
    src: url("../../assets/fonts/RobotoR.eot?#iefix")format("embedded-opentype");
    src: url("../../assets/fonts/RobotoR.woff") format("woff");
    src: url("../../assets/fonts/RobotoR.ttf");
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "RobotoBold";
    src: url("../../assets/fonts/RobotoB.eot");
    src: url("../../assets/fonts/RobotoB.eot?#iefix")format("embedded-opentype");
    src: url("../../assets/fonts/RobotoB.woff") format("woff");
    src: url("../../assets/fonts/RobotoB.ttf");
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "RobotoLight";
    src: url("../../assets/fonts/RobotoL.eot");
    src: url("../../assets/fonts/RobotoL.eot?#iefix")format("embedded-opentype");
    src: url("../../assets/fonts/RobotoL.woff") format("woff");
    src: url("../../assets/fonts/RobotoL.ttf");
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "RobotoMedium";
    src: url("../../assets/fonts/RobotoM.eot");
    src: url("../../assets/fonts/RobotoM.eot?#iefix")format("embedded-opentype");
    src: url("../../assets/fonts/RobotoM.woff") format("woff");
    src: url("../../assets/fonts/RobotoM.ttf");
    font-style: normal;
    font-weight: normal; }

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap');
