.b-slick-center {
	margin: 0 auto!important;
	width: 540px;
	text-align: center;
	padding: 10px 0 0; }

.slick-initialized .slick-slide {
	display: block; }

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: center;
	color: #000;
	padding-bottom: 7px;
	margin: 0 2px;
	border-bottom: 3px solid rgba(0,0,0,.0);
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: #27b29f;
		border-bottom: 3px solid #27b29f; } }

.slick-current {
	color: #27b29f;
	border-bottom: 3px solid #27b29f; }

.slick_show {
	display: inline-block;
	float: none;
	padding: 0 10px 7px; }

.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	top: 1px; }

.slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 18px;
	left: -35px;
	display: block;
	width: 20px;
	height: 19px;
	margin-top: -10px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: url(/assets/img/arrow_sprite.png)no-repeat 10px -182px;

	&:hover {
		background-position: 10px -167px; } }

.slick-next {
	right: -35px;
	left: inherit;
	background-position: -9px -182px;
	@extend .slick-prev;

	&:hover {
		background-position: -9px -167px; } }

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0; }

.slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0); }

.slick-list {
	@extend .slick-track; }

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block; }
