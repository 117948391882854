// Dashboard Login

.b-page-block {
	background: #fff;
	padding: 25px;
	box-sizing: border-box; }

.b-page-block.b-login-block {
	width: 525px;
	margin: 15px auto 0;
	text-align: center;
	padding-top: 20px;
	overflow: visible; }

.b-login-text {
	font-size: 21px;
	margin: 5px 0 15px;
	line-height: 26px; }

.b-input-login {
	border: 1px solid #bfbfbf;
	background: #fff;
	padding: 3px;
	font-size: 19px;
	width: 100%;
	text-align: center;
	font-family: "RobotoLight";
	transition: all 0.2s ease; }

.b-error-border {
	opacity: 0;
	visibility: hidden;
	width: 168px;
	height: 8px;
	background: #fb485e;
	transition: all 0.3s ease;
	margin: 0 auto;
	position: relative;
	top: -20px; }

.login-error {
	.b-error-border {
		opacity: 1;
		visibility: visible; }

	.b-input-login {
		background: #ffa4af;
		border-color: #ffa4af;
		color: #fff; }

	.b-input-login::-webkit-input-placeholder {
		color: #fff; }
	.b-input-login::-moz-placeholder {
		color: #fff; }
	.b-input-login:-moz-placeholder {
		color: #fff; }
	.b-input-login:-ms-input-placeholder {
		color: #fff; } }

.b-login-error-block {
	background: #ffa4af;
	color: #b41517;
	padding: 10px 0;
	display: none;
	position: relative;
	bottom: -30px;
	margin: 0 -65px;

	a {
		color: #730A0A; } }

.b-error-code {
	bottom: 0!important; }

// DASHBOARD LOGIN PASSWORD
.b-page-block.b-code.b-margin {
	margin-top: 100px; }

.b-page-block.b-code {
	margin-top: 0;
	background: #fff; }

.b-input-error {
	color: #fff; }

.b-input-error::-webkit-input-placeholder {
	color: #fff; }
.b-input-error::-moz-placeholder {
	color: #fff; }
.b-input-error:-moz-placeholder {
	color: #fff; }
.b-input-error:-ms-input-placeholder {
	color: #fff; }

// DASHBOARD RECOVERY
.b-login-select {
	padding: 4px 0 3px 2px!important; }

.b-page-block.js-password-block {
	background: #fff;
	margin-top: 0; }

.b-hide-block {
	overflow: hidden;
	position: relative;
	max-width: 525px;
	margin: 0 auto; }

.b-page-block.js-question-block {
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	background: #27b29f;

	label {
		color: rgba(255,255,255,.50); }

	p {
		color: #fff;
		margin: 0; } }

.b-page-block.js-password-block {
	margin: 0 auto;
	position: absolute; }

.b-close-btn {
	position: absolute;
	right: 10px;
	top: 15px;
	width: 20px;
	height: 20px;
	cursor: pointer; }

.b-close-block {
	position: absolute;
	right: 0;
	top: 7px;
	width: 20px;
	height: 3px;
	background: #94ECE0;
	cursor: pointer;
	transform: rotate(45deg);

	&:after {
		content: "";
		position: absolute;
		width: 20px;
		height: 3px;
		background: #94ECE0;
		transform: rotate(90deg);
		top: 0px;
		right: 0px; } }

.b-close-block.dark {
	background: #A2A7A6;

	&:after {
		background: #A2A7A6; } }
