* {
	padding: 0;
	margin: 0;
	border: none;
	border-collapse: collapse;
	border-spacing: 0;
	outline: none; }

ul {
	margin: 0;
	list-style: none; }
a {
	text-decoration: underline;

	&:hover {
		outline: none!important;
		text-decoration: underline;
		color: inherit; }

	&:active {
		outline: none!important; }

	&:focus {
		outline: none!important; } }

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit; }

.login-layout {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: url(/assets/img/login_bg.jpg)no-repeat center 0/cover;
	z-index: 9; }

.b-blackout {
	@extend .login-layout;
	display: none;
	background: rgba(0,0,0,.7);
	z-index: 999999; }

.container-fluid {
	margin: 0;
	padding: 0; }
