h1,h2,h3,h4 {
	margin: 0; }
p {
	font-family: "RobotoLight"; }

.b-no-float {
	float: none!important; }

.no-bottom-margin {
	margin-bottom: 0!important; }

.no-top-margin {
	margin-top: 0!important; }
// COLOR

.orange {
	background: #fb4000; }

.green {
	background: #24b19e; }

.gray {
	background: #9ca5aa; }

// FONTS
.f_12 {
	font-size: 12px!important; }

.f_14 {
	font-size: 14px!important; }

.f_15 {
	font-size: 15px!important;
	line-height: 15px; }

.f_16 {
	font-size: 16px!important; }

.f_18 {
	font-size: 18px!important; }

.f_20 {
	font-size: 20px!important; }

.f_22 {
	font-size: 22px!important;
	line-height: 25px; }

.f_light {
	font-family: "RobotoLight"!important; }

.f_regular {
	font-family: "RobotoRegular"!important; }

.f_medium {
	font-family: "RobotoMedium"!important; }

.f_bold {
	font-family: "RobotoBold"!important; }

.text_white {
	color: #fff; }

.text-align-left {
	text-align: left!important; }

.text-align-right {
	text-align: right!important; }

.text-align-center {
	text-align: center; }

.text-transparent {
	color: rgba(255,255,255,.75); }

.text-show {
	text-indent: 1px!important; }



// ICO
.i-edit_invis {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.3s ease; }

// INLINE BLOCK
.b-ib {
	display: inline-block; }

.b-valign-middle {
	vertical-align: middle; }
//

.b-page-content {
	width: 80.53%;
	margin: 0 auto;
	max-width: 1200px; }

.b-page-header {
	text-align: center;
	padding: 55px 0 10px;
	letter-spacing: 2px; }

.b-page-title {
	font: 500 20px "RobotoMedium", sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #000;
	text-align: center; }

.b-title_white {
	color: #fff; }

h3.b-page-title {
	font-size: 15px; }

.b-title_header {
	padding: 2px 0 2px 35px;
	display: inline-block; }

// PAGE TITLE ICO
.b-title_profile {
	background: url(/assets/img/profile-ico.png)no-repeat 0 center; }

.b-title_payout {
	background: url(/assets/img/pay-ico.png)no-repeat 0 center; }

.b-title_channels {
	background: url(/assets/img/channels-ico.png)no-repeat 0 center; }

.b-title_music {
	background: url(/assets/img/music-ico.png)no-repeat 0 center; }

.b-title_inbox {
	background: url(/assets/img/inbox-ico.png)no-repeat 0 center; }

.b-title_support {
	background: url(/assets/img/support-ico.png)no-repeat 0 center; }
//

// INPUT, SELECT
.b-page-input {
	border: 1px solid #b1b2b2;
	background: #fff;
	padding: 5px 0 4px 9px;
	box-sizing: border-box;
	width: 100%;
	font: 300 20px "RobotoLight", sans-serif;
	transition: border 0.3s ease;

	&:active {
		border: 1px solid #5d5e5e; }

	&:focus {
		border: 1px solid #5d5e5e; } }

.b-page-select {
	padding: 5px 0 4px 7px;
	font-size: 18px; }

::-webkit-input-placeholder {
	font: 12px "RobotoLight",sans-serif;
	line-height: 26px; }
::-moz-placeholder {
	font: 12px "RobotoLight",sans-serif;
	line-height: 26px; }
:-moz-placeholder {
	font: 12px "RobotoLight",sans-serif;
	line-height: 26px; }
:-ms-input-placeholder {
	font: 12px "RobotoLight",sans-serif;
	line-height: 26px; }

.b-page-input_xs {
	width: 110px;
	margin-right: 20px; }

.b-page-input_xxs {
	width: 90px; }

.b-input-error {
	background: #ffa4af;
	border-color: #ffa4af;
	color: #940707;

	&:hover {
		border-color: #ffa4af!important; }

	&:focus {
		border-color: #ffa4af!important; } }

.b-input-error::-webkit-input-placeholder {
	color: #b1b6b9; }
.b-input-error::-moz-placeholder {
	color: #b1b6b9; }
.b-input-error:-moz-placeholder {
	color: #b1b6b9; }
.b-input-error:-ms-input-placeholder {
	color: #b1b6b9; }

.b-input-check {
	display: none; }

input[type="checkbox"] {
	background: #faf; }

input[type="checkbox"] {
	+ label {
		display: inline-block;
		background: none;
		cursor: pointer;
		margin-bottom: 0;
 } }		// transition: all 0.3s ease

input[type="checkbox"] {
	+ label span {
		display: inline-block;
		width: 28px;
		height: 24px;
		vertical-align: middle;
		background: url(/assets/img/checkbox.png) no-repeat 0 0;
 } }		// transition: all 0.3s ease

input[type="checkbox"]:checked {
	+ label span {
		background-position: 0 -30px; } }

//
.b-hide-item {
	display: none!important; }

.b-page-block {
	background: rgba(251,251,251,.85);
	padding: 40px 65px 30px;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;

	&:last-child {
		margin-bottom: 65px;
		padding-bottom: 0; } }

.b-page-label {
	display: inline-block;
	font: 300 12px "RobotoLight",sans-serif;
	color: #9d9e9f;
	margin-bottom: 3px; }

.b-label_nm {
	margin-left: 0!important; }

.b-page-text {
	font: 300 20px "RobotoLight",sans-serif; }

// LINK
.b-link-wrap {
	display: inline-block;
	position: relative; }

.b-wrap_padding {
	padding-bottom: 25px; }

.b-modal-link-wrap {
	margin-left: 6%; }

.b-page-link {
	font: 400 14px "RobotoMedium",sans-serif;
	color: #3bb9a8;
	display: inline-block;
	margin-top: 20px;
	word-spacing: 0;
	text-decoration: underline;
	cursor: pointer;
	background: none;
	transition: all 0.3s ease;
	opacity: 1;

	&:hover {
		color: #309688!important; }

	.i-edit {
		margin-right: 8px; } }

.b-link {
	margin-top: 0;
	@extend .b-page-link; }


.b-error-link {
	color: #730A0A;

	&:hover {
		color: #730A0A; } }

.b-page-link_white {
	color: #fff;
	margin-top: 0;

	&:hover {
		color: #fff; } }

.b-link_hide {
	display: none; }

.b-link_invis {
	opacity: 0; }

.b-edit-ico {
	height: 12px;
	width: 12px;
	margin-top: 2px;
	background: url(/assets/img/edit.png)no-repeat 0 0; }
//

// BUTTONS
.b-buttons-wrap {
	text-align: center; }

.b-page-button {
	width: 200px;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	color: #fff;
	font-size: 24px;
	padding: 6px 0;
	margin: 25px 8px;
	transition: all 0.3s ease; }

.b-page-button_s {
	width: 130px; }

.b-button_yes {
	background: #fe4b00;

	&:hover {
		background: #d54b08; } }

.b-button_no {
	background: #9ba5aa;

	&:hover {
		background: #7c868b; } }

.b-buttons_s {
	width: 100px; }

.b-button_or {
	@extend .b-button_yes; }

button.b-page-button:disabled {
	background: #9ba5aa; }

.b-button_white {
	background: #fff;
	color: #000;
	margin: 40px 8px 30px;

	&:hover {
		background: #fff;
		color: #000; } }

.b-see-more {
	width: 250px;
	padding: 12px 0;
	margin: 35px 8px 10px; }

.b-page-form-buttons {
	text-align: center; }

.btn-primary {
	width: 100%;
	height: 100%; }
//

.b-error-text {
	padding: 10px 0 10px 40px;
	background: rgba(255,0,30,.3);
	border-top: 1px solid rgba(0,0,0,.2);
	border-bottom: 1px solid rgba(0,0,0,.2);
	text-align: left;
	display: none; }

// GREEN FORM
.b-page-form {
	background: #3ab8a7;
	padding: 30px 45px 5px;
	text-align: center;
	display: none;

	span {
		color: rgba(255,255,255,.50); }

	.b-page-label {
		color: rgba(255,255,255,.50)!important; }

	.b-page-input {
		padding-left: 15px; } }

.b-page-form-row {
	text-align: left;
	word-spacing: -0.25em;

	.b-page-label {
		color: #fff;
		margin-left: 20px;
		margin-bottom: 0; } }

.b-page-form-row__item {
	margin-bottom: 20px;
	display: inline-block;
	vertical-align: top;
	width: 49%;
	word-spacing: 0;
	margin-left: 2%;

	&:first-child {
		margin-left: 0; } }

.b-center-row {
	text-align: center!important; }

.b-agree-form {
	background: #fff;

	span {
		color: #fb3f00; } }

.b-unique-form {
	@extend .b-agree-form; }
//

// TABLE
.b-table {
	width: 100%!important; }

.b-table__head {
	color: #939697; }

.b-table__body {
	color: #000; }

tr {

	&:first-child {
		td {
			padding-top: 40px; } } }

tr.even {
	background: #eceeee;
	transition: background 0.3s ease; }

th {
	font-weight: 400!important;
	font-family: "RobotoRegular",sans-serif!important;
	padding-bottom: 10px;
	padding-left: 20px;
	color: #939697!important;
	white-space: nowrap;

	span {
		display: inline-block; }

	.sort_arrow {
		display: inline-block;
		width: 11px;
		height: 18px;
		margin: 0 0 -5px 5px;
		cursor: pointer; }

	&:nth-child(4) {
		text-align: center; }
	&:nth-child(5) {
		text-align: center; }
	&:nth-child(6) {
		text-align: center; }
	&:nth-child(7) {
		text-align: center; } }

th.sorting {
	.sort_arrow {
		background: url(/assets/img/arrow_sprite.png) no-repeat 0 1px; }

	&:hover {
		.sort_arrow {
			background-position: 0 -43px; } } }

th.sorting_asc {
	.sort_arrow {
		background: url(/assets/img/arrow_sprite.png) no-repeat 0 -131px; } }

th.sorting_desc {
	.sort_arrow {
		background: url(/assets/img/arrow_sprite.png) no-repeat 0 -87px; } }

td {
	font-family: "RobotoRegular",sans-serif;
	padding: 9px 0;
	padding-left: 20px;

	&:nth-child(4) {
		text-align: center; }
	&:nth-child(5) {
		text-align: center; }
	&:nth-child(6) {
		text-align: center; }
	&:nth-child(7) {
		text-align: center; }
	&:nth-child(9) {
		text-align: center; }

	a {
		color: #000;

		&:focus {
			color: #000; } } }
// TABLE END

// TABS
.b-tabs-wrap {
	position: relative; }

.b-page-tabs {
	margin: 0 -65px;
	background: #fff;
	text-align: center;
	border-bottom: 1px solid #d1d2d3;
	box-shadow: 0 1px 2px rgb(241,241,241); }

.b-tabs-item {
	display: inline-block;
	padding: 12px 25px 0;
	cursor: pointer;
	position: relative;

	&:hover {
		.b-tabs-title {
			color: #27b29f;
			border-bottom: 3px solid transparent; }

		.b-tabs_hover__item {
			opacity: 1;
			visibility: visible; } }

	.b-tabs-title {
		background: transparent;
		color: #000;
		text-decoration: none;
		padding-bottom: 7px;
		border-bottom: 3px solid transparent;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: all 0.3s ease; } }

.b-tabs-active {
	.b-tabs-title {
		color: #27b29f;
		border-bottom: 3px solid #27b29f;

		&:hover {
			border-bottom: 3px solid #27b29f; } } }

.b-tabs_hover__item {
	opacity: 0;
	visibility: visible;
	position: absolute;
	transition: all 0.3s ease;
	background: #fff;
	white-space: nowrap;
	z-index: 9;
	left: 5px;
	bottom: -38px;
	padding: 7px 20px; }

.b-box {
	position: absolute;
	left: -99999px;
	opacity: 0;
	transition: opacity 0.3s ease; }

.b-box.visible {
	position: relative;
	left: 0;
	top: 0;
	opacity: 1; }

.b-active-line {
	height: 3px;
	background: #212112;
	position: absolute;
	bottom: 0; }
// TABS END

// LEFT MENU
.b-left-menu {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	left: 0;
	top: 152px;
	z-index: 9999; }

.b-left-menu._show {
	opacity: 1;
	visibility: visible; }

.b-left-menu__item {
	position: relative;
	display: block;
	width: 38px;
	height: 38px;
	background: rgba(255,255,255,.55);
	margin-bottom: 2px;
	border-bottom: 1px solid transparent;
	transition: all 0.3s ease;

	&:hover {
		background: rgba(255,255,255,1);
		border-bottom: 1px solid #d1d8da;

		.b-munu-item_slide {
			left: 37px; } }

	a {
		display: block;
		height: 100%; }

	.i-edit {
		position: absolute;
		top: 0;
		left: 0;
		width: 37px;
		height: 100%;
		background: url(/assets/img/menu-sprite.png)no-repeat; }

	.i-edit-menu-profile {
		background-position: 6px 7px; }

	.i-edit-menu-channels {
		background-position: 6px -45px; }

	.i-edit-menu-pay {
		background-position: 6px -98px; }

	.i-edit-menu-inbox {
		background-position: 6px -152px; }

	.i-edit-menu-free {
		background-position: 6px -203px; }

	.i-edit-menu-new {
		background-position: 6px -257px; }

	.i-edit-menu-faq {
		background-position: 6px -310px; } }

.b-munu-item_slide {
	position: absolute;
	top: 0;
	left: -150px;
	padding-right: 10px;
	white-space: nowrap;
	color: #000;
	background: #fff;
	line-height: 37px;
	border-bottom: 1px solid #d1d8da;
	z-index: -1;
	transition: all 0.4s ease;

	&:after {
		content: "";
		position: absolute;
		right: -27px;
		border: 19px solid transparent;
		border-left: 9px solid #fff; }

	&:before {
		content: "";
		right: -29px;
		top: -1px;
		position: absolute;
		border: 20px solid transparent;
		border-left: 9px solid #d1d8da; } }
// LEFT MENU END

// AUTOCOMPLETE

.ui-autocomplete {
	width: 360px!important;
	background: #fff;
	padding: 10px 0;
	border: 1px solid #aaa;
	border-top: none; }

.ui-menu-item {
	padding: 4px 0 4px 20px;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		background: #dadada; } }

.ui-helper-hidden-accessible {
	display: none!important; }

// SPINNER

.navbar-spinner {
	background: url(/assets/img/spin.GIF)no-repeat 0 0/100%;
	width: 32px;
	height: 32px;
	display: inline-block;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 50%;
	margin-left: -16px;
	transition: all 0.3s ease; }

.navbar-spinner._show {
	opacity: 1;
	visibility: visible; }
//

// TOOLTIP
.b-tooltip {
	position: relative;
	cursor: pointer;
	white-space: normal;

	&:after {
		background: #9ca5aa;
		bottom: 29px;
		content: attr(data-desc);
		color: #fff;
		font: 9px "RobotoLight",sans-serif;
		min-width: 200px;
		min-height: 10px;
		left: -20px;
		position: absolute;
		padding: 16px 20px;
		opacity: 0;
		visibility: hidden;
		text-align: left;
		transition: all 0.2s ease; }

	&:before {
		content: "";
		position: absolute;
		border: 6px solid transparent;
		border-top: 13px solid #9ca5aa;
		bottom: 10px;
		left: 1px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease; }

	&:hover {
		&:after {
			opacity: 1;
			visibility: visible; }

		&:before {
			opacity: 1;
			visibility: visible; } } }

.b-tooltip_f-12 {
	&:after {
		font-size: 12px;
		padding: 6px 20px; } }

.b-tooltip-nowrap {
	&:after {
		white-space: nowrap;
		min-width: 60px; }
	&:before {
		left: 7px; } }

.b-tooltip_payoneer {
	&:before {
		left: 7px; } }

.b-tooltip_sum {
	&:after {
		font-size: 15px;
		padding: 7px 15px;
		text-align: left;
		bottom: -40px;
		min-width: 10px;
		white-space: nowrap; }

	&:before {
		left: 7px;
		bottom: -8px;
		border-bottom: 13px solid #9ca5aa;
		border-top: 1px solid transparent; } }

.b-tooltip-ex {
	padding: 2px 0 0 17px;
	background: url(/assets/img/tooltip.png)no-repeat 0 -20px; }

.b-tooltip-qu {
	margin-left: -15px;
	padding-left: 16px;
	height: 14px;
	background: url(/assets/img/tooltip.png)no-repeat  0 -19px; }

.b-tooltip-desc {
	background: #9ca5aa;
	bottom: 29px;
	color: #fff!important;
	font: 9px "RobotoLight",sans-serif;
	min-width: 200px;
	min-height: 10px;
	left: -20px;
	position: absolute;
	padding: 16px 20px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease, visibility .3s ease; }

.b-bank-tooltip {
	position: relative;
	cursor: pointer;

	&:before {
		content: "";
		position: absolute;
		border: 6px solid transparent;
		border-top: 13px solid #9ca5aa;
		top: -12px;
		left: 1px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease; }

	&:hover {
		.b-tooltip-desc {
			opacity: 1;
			visibility: visible; }

		&:before {
			opacity: 1;
			visibility: visible; } } }
// TOOLBAR
.b-menu-btn {
	cursor: pointer; }

.dropdown-toggle {
	background: none!important;
	border: none;
	padding: 0;

	&:hover {
		background: none;
		border: none; }

	&:focus {
		outline: none!important;
		box-shadow: none; }

	&:active {
		outline: none!important;
		box-shadow: none; } }

.dropdown-toggle[disabled] {
	opacity: 1;
	cursor: pointer; }

.dropdown-menu {
	display: block!important;
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	transition: all 0.2s ease; }

.open.active {
	.dropdown-menu {
		opacity: 1;
		visibility: visible;
		z-index: 99; } }

.b-item-count-wrap {
	font-size: 12px;
	margin: 0 -65px 5px;
	text-align: right;
	font-family: "RobotoMedium",sans-serif; }

// UP BTN
.b-up-button {
	display: none;
	text-transform: uppercase;
	padding: 40px 7px 1px;
	position: fixed;
	border: 4px solid #c1c6c8;
	color: #c1c6c8;
	cursor: pointer;
	bottom: 347px;
	right: 3%;
	background: url(/assets/img/back-ico.png)no-repeat 13px 4px; }

//

.b-850-block {
	width: 850px;
	margin: 0 auto;

	p {
		margin: 10px 0; } }

.b-850-title {
	text-transform: uppercase;
	font-size: 20px;
	color: #9ca5aa;
	letter-spacing: 2px;
	text-align: center;
	line-height: 27px; }

.b-slide-month-wrap {
	.slick-track {
		transform: none!important;
		left: 0!important; } }
