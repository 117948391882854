.b-channels-page-block {
	background: transparent;
	padding: 0 65px 30px;
	overflow: visible;

	.b-sum-block {
		padding: 20px 0; }

	.b-sum-block__item {
		padding: 15px 50px 15px 30px;
		white-space: nowrap;

		&:first-child {
			padding-left: 0; } } }

.b-sum-block_channels {
	opacity: 0;
	transition: all 0.3s ease; }

.b-sum-block_channels._show {
	opacity: 1; }

.b-sum-block_ref {
	position: absolute;
	width: 100%;
	top: 39px; }

.b-channels-actions {
	margin: 0 -65px;
	padding: 24px 65px;
	background: #fff;
	text-align: center; }

.b-select-items {
	float: left;
	background: #fb4000;
	height: 37px;
	line-height: 37px;
	padding: 0 8px;
	position: relative;
	transition: all 0.3s ease;

	&:hover {
		background-color: #d54b08; } }

.b-actions-btn {
	float: right;
	height: 37px;
	line-height: 37px;
	background: url(/assets/img/action-ico.png)no-repeat 9px center #9ba5aa;
	color: #fff;
	margin-right: 25px;
	position: relative;
	width: 122px;
	transition: color 0.3s ease;

	&:hover {
		background-color: #7c868b; }

	// span
	// 	margin-left: 12px

	.b-drop-menu-wrap {
		&:after {
			right: 9px; }
		&:before {
			right: 8px; } } }

.b-actions-btn.active {
	background-color:  #fb4000;

	&:hover {
		background-color: #d54b08; } }

input[type="checkbox"].b-all-check {
	+ label span {
		width: 23px;
		height: 23px;
		margin-top: -3px;
		vertical-align: middle;
		background: url(/assets/img/white_check.png) no-repeat 0 -30px;
 } }		// transition: all 0.3s ease

input[type="checkbox"].b-all-check:checked {
	+ label span {
		background-position: 0 0; } }

input[type="checkbox"].b-all-check.dash {
	+ label span {
		background-position: 0 -60px; } }

.action-name {
	display: inline-block;
	vertical-align: top;
	margin-top: 0;
	margin-left: 22px; }

.b-drop-ico {
	display: inline-block;
	width: 11px;
	height: 8px;
	margin-left: 9px;
	position: relative;
	cursor: pointer;
	background: url(/assets/img/channels_sprite.png)no-repeat -6px -205px;
	transition: all 0.3s ease; }

.b-drop-ico.preload {
	background: url(/assets/img/spin-gray.GIF)no-repeat 0 0/100%;
	width: 16px;
	height: 16px;
	top: 2px; }

.b-drop-menu-wrap {
	position: absolute;
	top: 35px;
	right: 0;
	left: inherit;
	padding-top: 10px;
	border-radius: 0;
	background: #E6E9EA;
	border: 1px solid #aaa;
	transition: all 0.3s ease;

	&:after {
		content: "";
		position: absolute;
		width: 10px;
		border: 7px solid transparent;
		border-bottom: 8px solid #E6E9EA;
		top: -14px;
		right: 6px;
		z-index: 9999; }


	&:before {
		content: "";
		position: absolute;
		width: 10px;
		border: 8px solid transparent;
		border-bottom: 9px solid #aaa;
		top: -17px;
		right: 5px;
		z-index: 9999; } }

.b-drop-menu-item {
	text-align: left;
	white-space: nowrap;
	cursor: pointer;
	line-height: 23px;
	padding: 2px 10px;
	color: #4a4c4d;
	transition: all 0.2s ease;

	&:hover {
		background: #fff; } }

.b-search-input {
	width: 310px;
	float: right;
	padding: 6px 0 5px 16px;
	height: 37px;
	font: 16px "RobotoRegular",sans-serif;
	color: #000;

	&:hover {
		border: 1px solid #5a5a5a; } }

.b-search-input::-webkit-input-placeholder {
	line-height: 18px; }
.b-search-input::-moz-placeholder {
	line-height: 23px; }
.b-search-input:-moz-placeholder {
	line-height: 23px; }
.b-search-input:-ms-input-placeholder {
	line-height: 18px; }

.b-search-btn {
	float: right;
	width: 50px;
	height: 37px;
	background: url(/assets/img/search.png)no-repeat center #fb4000;
	position: relative;
	left: -1px;
	transition: all 0.3s ease;

	&:hover {
		background-color: #d54b08; } }

// CHANNELS TABLE
.b-status {
	text-indent: 0;
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 25px;
	padding-left: 30px;
	font-family: "RobotoRegular",sans-serif;
	background: url(/assets/img/payout_sprite.png)no-repeat;
	color: #8A8C8E; }

.b-status_good-standing {
	background-position: -30px -54px; }

.b-status_bad-standing {
	background-position: 0 -118px; }

.b-status_pending {
	background-position: 0 -86px; }

.b-channels-layer {
	top: 25px; }

.b-channels-table-head {
	th {
		padding-bottom: 30px;
		white-space: nowrap;

		&:nth-child(4) {
			text-align: left; }
		&:nth-child(5) {
			text-align: left; }
		&:nth-child(6) {
			text-align: left; }
		&:nth-child(7) {
			text-align: left; }
		&:nth-child(8) {
			text-align: center; }
		&:nth-child(9) {
			text-align: left; }
		&:nth-child(10) {
			text-align: center; } } }

.b-channels-page-block {

	.b-channels-table-body {
		tr {
			transition: none;
			border: 1px solid #fff; }

		tr.selected {
			background: #fb4000;
			color: #fff!important;

			a {
				color: #fff; }

			.b-status {
				background-position: -31px -86px;
				color: #fff!important; }

			.b-status_bad-standing {
				background-position: -31px -118px; }

			.b-download-link_pdf {

				.i-edit {
					background-position: -50px 1px; }

				.i-edit_invis {
					background-position: -76px -24px; }

				&:hover {
					.i-edit_invis {
						visibility: visible;
						opacity: 1; } } }

			.autoclaim-active {
				background-position: -1px -75px!important; }

			.favorite-active {
				background-position: -4px -138px!important; }

			.i-edit-autoclaim {
				background-position: -1px -49px;

				&:hover {
					background-position: -1px -75px; } }

			.i-edit-favorite {
				background-position: -3px -229px;

				&:hover {
					background-position: -4px -138px; } } }

		tr {
			&:first-child {
				td {
					padding-top: 7px; } }

			td {
				&:nth-child(4) {
					text-align: left; }
				&:nth-child(5) {
					text-align: left; }
				&:nth-child(6) {
					text-align: left; }
				&:nth-child(7) {
					text-align: left; }
				&:nth-child(8) {
					text-align: center; }
				&:nth-child(9) {
					text-align: center; }
				&:nth-child(10) {
					text-align: center; } } } } }

.b-channel-check {
	padding-left: 20px; }

.b-channel-autoclaim {
	padding-left: 0;
	padding-right: 5px;
	text-align: center; }

.b-channel-favorite {
	padding-left: 0; }

.b-channel-name {
	padding-left: 5px;

	a {
		span {
			display: inline-block;
			max-width: 140px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			text-decoration: underline;
			margin-bottom: -6px; } } }

.b-referrals-row {
	width: 28%; }

.b-download-link_pdf {
	margin-left: 0;
	background: none; }

.i-edit-autoclaim {
	width: 21px;
	height: 11px;
	margin: 0 5px;
	vertical-align: middle!important;
	background: url(/assets/img/channels_sprite.png)no-repeat -1px -26px; }

.autoclaim-active {
	background-position: -1px 0; }

.i-edit-autoclaim {
	&:hover {
		background-position: -1px -49px; } }

.b-autoclaim {
	background: none; }

.b-favorite {
	background: none; }

.i-edit-favorite {
	width: 19px;
	height: 18px;
	background: url(/assets/img/channels_sprite.png)no-repeat -4px -174px; }

.i-edit-favorite {
	&:hover {
		background-position: -4px -229px; } }

.favorite-active {
	background-position: -3px -103px;
	&:hover {
		background-position: -3px -103px!important; } }

.b-channel_id {
	position: relative;

	&:after {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		content: attr(channel-data);
		display: block;
		min-width: 100px;
		padding: 3px 7px;
		background: #cfd7db;
		color: #525557;
		top: -33px;
		white-space: nowrap;
		transition: all 0.3s ease; }

	&:before {
		opacity: 0;
		visibility: hidden;
		content: "";
		position: absolute;
		top: -10px;
		left: 5px;
		border: 5px solid transparent;
		border-top: 10px solid #cfd7db;
		transition: all 0.3s ease; }

	&:hover {
		&:after {
			opacity: 1;
			visibility: visible; }

		&:before {
			opacity: 1;
			visibility: visible; } } }

.b-text-overflow {
	max-width: 130px;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	vertical-align: bottom; }

.b-referrals-name {
	display: inline-block;
	width: 240px; }

.b-not-signed {
	cursor: pointer;
	background: url(/assets/img/not-signed.png)no-repeat 0 0; }

.nicescroll-rails {
	top: 187.5px!important; }

.channels-table {
	.b-channel-check {
		width: 6%; }

	.b-channel-autoclaim {
		width: 3%; }

	.b-channel-favorite {
		width: 3%;
		text-align: center; }

	.b-channel-name {
		padding-left: 15px; }

	td {
		&:nth-child(4) {
				width: 14%; }
		&:nth-child(5) {
				width: 12%; }
		&:nth-child(6) {
				width: 12%; }
		&:nth-child(7) {
				width: 12%; }
		&:nth-child(8) {
				width: 15%; }
		&:nth-child(9) {
				width: 8%; } } }
//

// REFERRALS TABLE
.refferal-table {
	thead {
		th {
			&:nth-child(4) {
				text-align: center!important; }
			&:nth-child(5) {
				text-align: center!important; } } }
	tbody {

		td {
			&:first-child {
				width: 21%; }
			&:nth-child(2) {
				width: 20%; }
			&:nth-child(3) {
				width: 17%; }
			&:nth-child(4) {
				width: 21%;
				text-align: center!important; }
			&:nth-child(5) {
				text-align: center!important;

				.b-status {
					position: relative;
					right: 20px; } } } } }
//

.js-channels-list {
	opacity: 1;
	visibility: visible;
	transition: all 0.15s ease; }

.js-channels-list._hide {
	opacity: 0;
	visibility: hidden; }

.b-channels-layer {
	@extend .js-channels-list; }

.b-channels-layer._hide {
	@extend .js-channels-list._hide; }

//

.b-channels-count-up {
	margin-top: 10px;
	margin-bottom: -35px; }

.b-channels-count-down {
	margin-top: 40px;
	margin-bottom: -12px;
	padding-bottom: 8px; }

.b-referral-link-wrap {
	float: left;
	text-align: left;

	span {
		font-family: "RobotoMedium"; } }
