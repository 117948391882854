.navbar {
	border-radius: 0;
	border: none; }

.b-header._login {
	background: none;
	box-shadow: none;

	.navbar-left {
		display: none; }
	.navbar-login {
		display: none; } }

.b-header._login._scroll {
	background: none;
	box-shadow: none; }

.b-header._scroll {
	background: #fff; }

.b-header.hide-login {
	.navbar-login {
		display: none; } }

.b-header {
	min-width: 330px;
	background: rgba(255,255,255,.75);
	height: 56px;
	width: 100%;
	padding: 1px 8.3%;
	position: fixed;
	box-sizing: border-box;
	z-index: 9999;
	box-shadow: 0 0 2px rgba(0,0,0,.2);
	transition: background 0.3s ease;

	.navbar-header {
		width: 83.5%;
		margin: 0 auto;
		position: fixed;

		.navbar-logo {
			position: relative;

			&:hover {
				.i-edit-logo_hover {
					opacity: 1;
					visibility: visible; } }

			.i-edit_logo {
				width: 93px;
				height: 54px;
				background: url(/assets/img/logo.png) no-repeat 0 1px; }

			.i-edit-logo_hover {
				background: url(/assets/img/logo.png) no-repeat 0 -54px;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
				transition: all 0.3s ease; } } }

	.navbar-right {
		float: right;
		margin: 0;
		position: relative;

		.navbar-login {
			overflow: hidden; }

		.navbar-login-button {
			text-decoration: none;
			font-size: 18px;
			color: #fff;
			display: inline-block;
			padding: 3px 15px;
			border: 1px solid #fff;
			margin-top: 11px;
			transition: background 0.3s ease;
			position: relative;
			background: rgba(0,0,0,.4);

			&:hover {
				background: rgba(213, 75, 8, 0.5); } }

		.navbar-login-button._active {
			background: rgba(213, 75, 8, 0.5); }

		.navbar-spinner {
			display: none;
			position: absolute;
			width: 32px;
			height: 32px;
			background: url(/assets/img/spin.GIF)no-repeat 0 0/100%;
			top: 12px;
			right: 8px; } }

	.navbar-login-select {
		position: absolute;
		background: #fff;
		left: -50px;
		top: 55px;
		width: 190px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease;

		&:after {
			content: "";
			position: absolute;
			border: 5px solid transparent;
			border-bottom: 11px solid #fff;
			top: -16px;
			left: 50%;
			margin-left: -5px; } }

	.navbar-login-select._show {
		opacity: 1;
		visibility: visible; }

	.navbar-login-item {
		white-space: nowrap;
		text-align: center;
		padding: 12px 0;
		transition: all 0.3s ease;

		&:first-child {
			border-bottom: 1px solid #dee0e1;
			box-shadow: 0 1px 0 #f1f3f4;
			line-height: 21px; }

		&:last-child {
			text-align: left; }

		&:hover {
			background: #d8e1e5; }

		.i-edit-open {
			width: 23px;
			height: 23px;
			margin: -2px 9px 0 14px;
			background: url(/assets/img/plus.png)no-repeat 0 0;
			transition: transform 0.3s ease;
			cursor: pointer; }

		.i-edit-open._open {
			transform: rotate(45deg); }

		a {
			color: #000;
			text-decoration: none; } }

	.navbar-pa-desc {
		white-space: normal;
		color: #fff;
		font: 12px "RobotoLight",sans-serif;
		text-align: left;
		background: #27b29f;
		display: none;
		margin: 11px 0 -12px 0;
		padding: 11px;
		line-height: 15px; }

	.navbar-g-info {
		@extend .navbar-right;
		text-align: right;
		margin: 15px 30px 0 0!important;
		font-size: 12px;
		line-height: 12px;
		color: #646465;

		.navbar-info-email {
			font-size: 10px; } }

	.navbar-g-ico {
		@extend .navbar-right;
		margin-top: 3px;
		cursor: pointer;
		position: relative;

		&:hover {
			.navbar-menu {
				visibility: visible;
				opacity: 1; } } }

	.navbar-g-ico-img {
		width: 48px;
		height: 48px;
		border-radius: 100%;
		border: 2px solid #fb4000;
		transition: border 0.3s ease;

		&:hover {
			border: 2px solid #d54b07; } }

	.navbar-menu {
		position: absolute;
		background: #cfd7db;
		left: -7px;
		top: 52px;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease;

		&:after {
			content: " ";
			left: 28px;
			position: absolute;
			top: -12px;
			z-index: 999;
			border: 4px solid transparent;
			border-bottom: 8px solid #cfd7db; }


		a {
			text-decoration: none;
			color: #7c868b;
			display: block;
			white-space: nowrap;
			padding: 5px 15px;
			transition: color 0.3s ease;

			&:hover {
				color: #000;
				background: #d8e1e5; } } }


	.navbar-menu-item {
		list-style: none;
		border-bottom: 1px solid #b9c1c5;

		&:last-of-type {
			border-bottom: none; } } }

.b-header-switch-pr {
	float: right;
	position: relative;
	height: 23px;
	width: 23px;
	margin-top: 15px;
	margin-right: 30px;

	&:hover {

		> .i-edit-invis {
			opacity: 1;
			visibility: visible; } }

	> .i-edit {
		background: url("/assets/img/header-sprite.png")no-repeat -7px -79px;
		width: 100%;
		height: 100%;
		cursor: pointer; }

	> .i-edit-invis {
		background-position: -45px -79px; } }

.b-product-list {
	position: absolute;
	background: #cfd7db;
	top: 40px;
	right: 0;
	padding: 19px 19px 14px 19px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease, visibility .3s ease;

	&:after {
		content: " ";
		right: 7px;
		position: absolute;
		top: -12px;
		z-index: 999;
		border: 4px solid transparent;
		border-bottom: 8px solid #cfd7db; } }

.b-product-list._show {
	opacity: 1;
	visibility: visible; }

.b-product-item {
	font-size: 9px;
	font-family: "RobotoMedium",sans-serif;
	white-space: nowrap;
	position: relative;
	text-align: center;
	cursor: pointer;

	div {
		padding-top: 2px; }

	.i-edit {
		width: 35px;
		height: 35px;
		background: url("/assets/img/header-sprite.png")no-repeat -1px 0; }

	.i-edit-invis {
		background-position: -41px 0; }

	&:hover {
		.i-edit-invis {
			opacity: 1;
			visibility: visible; } } }

.b-pr-inbox-list {
	float: right;
	margin-right: 30px;
	margin-top: 15px; }

.b-pr-inbox-item {
	width: 25px;
	height: 25px;
	position: relative;
	cursor: pointer;

	&:hover {
		.i-edit-invis {
			opacity: 1;
			visibility: visible; } }

	.i-edit {
		width: 100%;
		height: 100%;
		background: url("/assets/img/header-sprite.png")no-repeat -5px -43px; }

	.i-edit-invis {
		background-position: -45px -43px; }

	.b-inbox-count {
		left: 16px;
		right: inherit;
		top: -8px;
		font-size: 8px;
		background: #fb4000;

		&:after {
			background: #fb4000; } } }
