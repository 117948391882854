body._login {

	.b-main-wrapper {
		background: url(/assets/img/login_fon.jpg)no-repeat center 0/cover; }

	.wrapper_n {
		opacity: 0; } }

.wrapper_n.no_p {
	padding: 0; }

.b-join-content {
	font-family: "Roboto Condensed";
	font-size: 18px;
	color: #fff; }

.b-join-header {
	background: url(/assets/img/join_page/join_bg.jpg) no-repeat center 0/cover #000;
	padding-top: 80px;
	word-spacing: -0.25em; }

.b-join-title {
	width: 340px;
	margin: 0 auto 20px;
	font-size: 29px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 7px; }

.b-join-button {
	display: block;
	width: 340px;
	margin: 0 auto;
	font-size: 38px;
	text-transform: uppercase;
	border: 4px solid #fff;
	padding: 30px 0;
	background: rgba(0,0,0,.5);
	color: #fff;
	text-decoration: none;
	text-align: center;
	font-weight: bold;
	letter-spacing: 7px;
	box-sizing: border-box;
	transition: all 0.3s ease;

	&:hover {
		background: rgba(213,75,8,.5);
		text-decoration: none; } }

.b-join-stats {
	word-spacing: -0.25em; }

.b-stats_item {
	width: 33.333%;
	display: inline-block;
	vertical-align: top;
	padding: 68px 40px;
	margin-top: 140px;
	text-align: center;
	box-sizing: border-box;
	word-spacing: 0; }

.b-stats-item_orange {
	background: #fb3e00; }

.b-stats-item_l-trans {
	background: rgba(0,0,0,.5); }

.b-stats-item_m-trans {
	background: rgba(0,0,0,.7); }

.b-stats-item_m_green {
	background: #2db3a1;
	color: #000; }

.b-stats-item_l_green {
	background: #76cec2;
	color: #000; }

.b-item-big {
	height: 335px;
	margin: 0; }

.b-stats_left {
	display: inline-block;
	vertical-align: middle; }

.b-stats_right {
	text-align: left;
	font-weight: 300;
	@extend .b-stats_left; }

.b-stats_left__item {
	@extend .b-stats_left; }

.b-stats-item__number {
	font-size: 64px;
	font-weight: bold;
	font-family: "Oswald"; }

.b-stats-sub {
	font-size: 17.7px;
	font-weight: bold;
	position: relative;
	top: -23px; }

.b-stats-desc {
	@extend .b-stats_left;
	font-family: "Roboto Condensed Light";
	font-size: 21px;
	margin-top: -9px; }

.b-stats-desc_upper {
	text-transform: uppercase;
	font-size: 32px;
	margin-top: 0; }

.b-stats-title {
	font-size: 22px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 4px;
	margin-bottom: 20px; }

.b-stats-text {
	font-weight: 300;
	text-align: left;
	font-family: "Roboto Condensed Light"; }
