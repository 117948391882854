@media screen and (max-width: 5000px) {
	.b-content-stats__left {
		width: 66.02%;
		margin-right: 11px; }

	.b-content-stats-right_invis {
		display: none!important; } }

@media screen and (max-width: 1440px) {
	.b-content-wrapper {
		min-width: 850px; } }

@media screen and (max-width: 1120px) {
	.b-stats-text {
		font-size: 16px; } }

@media screen and (max-width: 950px) {
	.b-stats-title {
		font-size: 20px; }

	.b-stats_item {
		padding-top: 40px; }

	.b-join-header {
		.b-stats_item {
			min-height: 270px;

			div.b-stats_left {
				min-width: 110px; } } } }

@media screen and (max-width: 1023px) {
	.b-main-wrapper {
		.b-content-wrapper {
			min-width: 558px; }

		.b-content-stats__left {
			width: 100%;
			margin: 0; }

		.b-content-stats__right {
			width: 100%;
			display: none!important; }

		.b-content-stats-right_invis {
			display: block!important; }

		.b-content-stats-item_tight {
			min-width: 175px;
			width: 31.6%; }

		.b-content-stats__text {
			span {
				display: block;

				&:last-of-type {
					display: block;
					margin-top: 5px; } } } } }

@media screen and (max-width: 769px) {

	.b-join-header {
		.b-stats_item {
			min-height: 270px;

			div.b-stats_left {
				min-width: 110px; } } } }


@media screen and (min-width: 120px) and (max-width: 480px) {
	//sdfs
	.b-profile-block {
		padding: 30px 10px 30px 10px;

		.b-profile-settings {
			display: none; }

		.b-edit__item {
			display: inline; }

		.b-profile-edit-link {
			display: none; }

		.b-edit-input-block {
			display: none; } }



 }	//sdfs


@media screen and (max-width: 767px) {

	//sdfs
	.b-profile-block {
		.b-edit__item {
			display: inline; }

		.b-profile-edit-link {
			display: none; }

		.b-edit-input-block {
			display: none; } }

	//sdfs


	// HEADER
	.navbar-left {
		float: none;
		text-align: center; }

	.navbar-logo .i-edit_logo {
		margin-left: 20px; }

	.navbar-slide-ico {
		display: block;
		position: fixed;
		right: 0;
		top: 0;
		width: 60px;
		height: 56px;
		cursor: pointer;
		border-left: 2px solid #d1dada; }

	.navbar-menu-ico {
		width: 100%;
		height: 100%; }

	.navbar-menu-ico.show {

		.navbar-menu-row {
			background: none;

			&:after {
				-webkit-transform: rotate3d(0, 0, 1, -45deg);
				top: 0; }

			&:before {
				-webkit-transform: rotate3d(0, 0, 1, 45deg);
				top: 0; } }

		+ .navbar-slide-menu {
			right: 0; } }

	.navbar-menu-row {
		display: block;
		width: 24px;
		height: 3px;
		background: #000;
		top: 50%;
		left: 50%;
		margin-top: -1.5px;
		margin-left: -12px;
		position: absolute;
		transition: all 0.1s ease;

		&:after {
			position: absolute;
			top: -7px;
			content: '';
			display: block;
			width: 24px;
			height: 3px;
			background: #000;
			-webkit-transform: rotate3d(0, 0, 1, 0deg);
			transition: transform 0.3s ease; }

		&:before {
			position: absolute;
			top: 7px;
			content: '';
			display: block;
			width: 24px;
			height: 3px;
			background: #000;
			-webkit-transform: rotate3d(0, 0, 1, 0deg);
			transition: transform 0.3s ease; } }

	.navbar-slide-menu {
		position: fixed;
		top: 0;
		bottom: 0;
		height: 100%;
		background: rgba(225,226,226,.9);
		min-width: 305px;
		width: 305px;
		right: -500px;
		z-index: 9999999;
		transition: all 0.3s ease;

		.navbar-menu-ico {
			height: 45px;
			width: 60px;
			position: relative; } }

	.navbar-slide-item {
		list-style: none;

		&:first-child {
			border-top: 1px solid #d9dbdc; }

		&:last-child {
			border-bottom: 1px solid #c6c8c9; }

		a {
			display: block;
			font-size: 20px;
			text-decoration: none;
			text-align: center;
			color: #000;
			padding: 25px 0;
			border-top: 1px solid #c6c8c9;
			border-bottom: 1px solid #d9dbdc; } }

	.navbar-info {
		display: none; }
	// HEADER END
	// WRAPPER
	.b-main-wrapper {
		.b-content-info {
			width: 100%;
			height: 165px;
			background: url(/assets/img/mobile-fon.jpg) no-repeat center;
			position: absolute;
			left: 0;
			display: none;
			text-align: center;
			box-sizing: border-box; }

		.b-info-g-ico {
			padding-top: 15px; }

		.b-info-g-ico__img {
			width: 86px;
			height: 86px;
			border-radius: 100%;
			border: 2px solid #fb4000; }

		.b-info-name {
			font-size: 15px;
			text-transform: uppercase; }

		.b-info-email {
			font-size: 11px; }

		.b-content-news {
			padding-top: 185px;
			padding-top: 0px; }

		.b-content-title-new {
			display: none; }

		.b-content-news-text {
			width: 100%; }

		.b-content-wrapper {
			min-width: 311px; }

		.b-content-stats__item {
			padding: 0 0 0 19px; }

		.b-content-stats__right {
			width: 100%;
			display: block!important;
			margin-bottom: 40px; }

		.b-content-stats-right_invis {
			display: none!important; }

		.b-content-stats-item_center {
			border-right: none; }

		.b-content-stats-item_right {
			display: none!important; }

		.b-content-stats-item_tight {
			min-width: 175px;
			width: 100%; }

		.b-content-stats__number {
			font-size: 45px;
			padding-right: 10px; }

		.b-content-stats__text {
			font-size: 16px; }

		.b-stats-name {
			font-weight: bold;
			font-size: 25px;
			line-height: 25px!important; }

		.b-content-stats__down-desc {
			left: 19px;
			width: 150px; }

		.b-content-stats-desc_invis {
			display: block; } }
	// WRAPPER END
	// FOOTER
	.b-footer {
		height: 222px!important; }

	.b-footer__socials {
		a {
			&:first-child {
				display: block;
				width: 92px;
				height: 52px;
				margin: 20px auto 40px;

				span {
					margin-right: 0!important; } } } }
 }	// FOOTER END
@media screen and (min-width: 1024px) {
	.b-content-wrapper {

		.b-content-stats__right {
			width: 32.52%; }

		.b-content-stats-item_tight {
			width: 100%; } } }

@media screen and (min-width: 768px) {
	.navbar-left {
		float: left; }

	.navbar-slide-ico {
		display: none; }

	.b-content-info {
		display: none; }

	.b-content-title-new {
		min-width: 13.3%;
		display: block; }

	.b-content-news-text {
		width: 66.6%; }

	.b-content-stats__item {
		padding: 0 0 0 25px; }

	.b-content-stats-item_center {
		width: 46.76%;
		border-right: 1px solid rgba(191,191,191,.5); }

	.b-content-stats__text {
		font-size: 20px; }

	.b-content-stats__number {
		font-size: 60px;
		padding-right: 20px; }

	.b-content-stats__down-desc {
		left: 25px; }

	.b-content-stats-desc_invis {
		display: none; } }
