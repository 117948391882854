.b-page-header-wrap {
	position: relative;
	display: inline-block;

	.b-inbox-count {
		top: 1px;
		right: -21px; } }

.b-inbox-header {
	margin-bottom: 55px; }

.b-inbox-count {
	position: absolute;
	top: 7px;
	right: 3px;
	border-radius: 11px;
	background: #3ab9a8;
	color: #fff;
	font-size: 10px;
	padding: 2px 5px 2px 5px;
	line-height: 11px;
	letter-spacing: 0;

	&:after {
		content: '';
		position: absolute;
		width: 6px;
		height: 6px;
		display: block;
		background: #3bb9a8;
		border-radius: 100%;
		right: -2px;
		top: -1px; } }

.b-inbox-page-block {
	background: transparent;
	padding: 0 65px 30px;
	overflow: visible; }

.b-tickets-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: "RobotoMedium";
	margin-bottom: 4px;
	display: inline-block; }

.b-open-tickets {
	margin-bottom: 20px; }

.b-inbox-body {
	margin: 0 -65px 0;
	padding: 55px 65px 35px;
	background: #fff; }

// TABLE
.b-inbox-page-block {
	tr.selected {
		background: #fb4000;
		color: #fff;

		.favorite-active {
			background-position: -4px -138px!important; }

		.i-edit-favorite {
			background-position: -4px -229px;

			&:hover {
				background-position: -4px -138px!important; } } }


	tr {
		border-bottom: 1px solid #fff;
		transition: none;
		&:first-child {
			td {
				padding-top: 9px; } } }
	tr._show {
		td {
			padding-left: 102px;
			font-family: "RobotoRegular";
			color: #686b6d;

			p {
				width: 85%; } } } }

tr.b-unread-row {
	.b-inbox-title {
		font-family: "RobotoBold",sans-serif; } }

.b-inbox-table {
	.b-channel-favorite {
		width: 26px; } }

.b-inbox-check {
	width: 65px; }

.b-inbox-title {
	cursor: pointer;
	padding-left: 10px; }

.b-inbox-date {
	width: 110px;
	cursor: pointer; }

.details-control {
	width: 47px;
	cursor: pointer; }

.b-inbox-item-header {
	line-height: 40px; }

.b-inbox-item-header._open {
	.i-edit-arrow {
		transform: rotate(90deg); } }

.b-inbox-favorite {
	margin: 3px 15px 0; }

.i-edit.i-inbox-favorite {
	vertical-align: middle;
	margin-top: -3px;

	&:hover {
		background-position: -3px -229px; } }

.b-inbox-date {
	margin-right: 10px; }

.i-edit-arrow {
	width: 8px;
	height: 12px;
	background: url(/assets/img/arrow_sprite.png) no-repeat -9px -169px;
	margin-top: 1px;
	margin-right: 5px;
	transition: all 0.3s ease; }

.i-edit-arrow.details {
	transform: rotate(90deg); }

.b-inbox-item-body {
	max-height: 0;
	max-width: 750px;
	position: relative;
	left: 83px;
	transition: all 0.2s ease; }

.b-inbox-item-body._show {
	max-height: 3000px;
	padding: 5px 0 20px; }

.b-inbox-name {
	color: #000;
	font-family: "RobotoBold",sans-serif;
	display: block;
	margin-bottom: 10px; }

.b-inbox-question {
	color: #686b6d;
	padding-bottom: 10px;
	border-bottom: 1px solid #dcddde; }

.b-inbox-answer {
	padding-top: 10px;
	border-top: 1px solid #e2e5e7; }

//

.b-create-ticket-block {
	margin: 0 -65px;
	opacity: 0;
	left: -99999px;
	position: absolute;
	transition: opacity 0.3s ease, height 0.3s ease; }

.b-create-ticket-block._show {
	opacity: 1;
	position: inherit; }

.b-ticket-form {
	display: block;
	overflow: hidden;
	transition: height 0.3s ease; }

.b-create-ticket-btn {
	display: inline-block;
	width: 28px;
	height: 29px;
	background: url(/assets/img/close-ico.png)no-repeat 0 0;
	margin: 10px 0;
	transform: rotate(-45deg);
	transition: all 0.3s ease;
	cursor: pointer; }

.b-create-ticket-btn._open {
	transform: rotate(0deg); }

textarea {
	height: 140px;
	max-width: 1026px; }

.b-upload-block {
	margin: 0 -60px;
	padding: 20px 60px;
	opacity: 0;
	left: -999999px;
	position: absolute;
	transition: opacity 0.3s ease;
	border-top: 1px solid #56bcaf;
	box-shadow: 0 -1px 0 #43a99c;

	textarea {
		max-width: 1500px; } }

.b-upload-block._show {
	opacity: 1;
	position: inherit;
	left: 0; }

.b-upload-list {
	margin: 10px 0; }

.b-upload-file-wrap {
	position: relative;
	overflow: hidden;
	width: 180px;
	margin: 0 auto 15px; }

.b-upload-btn {
	display: inline-block;
	padding-right: 45px;
	padding-top: 6px;
	background: url(/assets/img/upload-ico.png)no-repeat right top;
	cursor: pointer; }

.b-upload-btn.max {
	background-position: right -29px; }

.b-upload-list input {
	cursor: pointer;
	font-size: 150px;
	filter: alpha(opacity=1);
	-moz-opacity: 0.01;
	opacity: 0.01;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -84px; }

.b-upload-file-item {
	display: inline-block;
	padding: 10px 15px 10px 20px;
	background: rgba(0,0,0,.2);
	min-width: 450px;
	text-align: left;
	vertical-align: top;
	border-right: 1px solid #469c92;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0; } }

.b-file-name {
	max-width: 220px;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
	margin-right: 10px;
	white-space: nowrap; }

.b-file-size {
	color: #aacdc7!important; }

.b-file-progress {
	float: right;
	width: 115px;
	height: 15px;
	margin-top: 2px;
	background: #fff; }

.b-progress-state {
	display: block;
	height: 15px;
	background: #fb4000;
	width: 0;
	transition: all 0.3s ease; }

.b-file-remove {
	height: 39px;
	width: 35px;
	vertical-align: top;
	margin-left: -3px;
	background: rgba(0,0,0,.2);
	border-left: 1px solid #32897f;
	position: relative;
	cursor: pointer;

	span {
		height: 3px;
		width: 18px;
		background: #fff;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -1px;
		margin-left: -9px;
		transform: rotate(45deg);
		border-radius: 10px;

		&:after {
			content: "";
			height: 3px;
			width: 18px;
			background: #fff;
			display: block;
			position: absolute;
			transform: rotate(-90deg);
			border-radius: 10px; } } }

.b-success-ticket {
	margin: 0 -45px -6px;
	padding: 10px 0;
	background: #259182;
	border-top: 1px solid #469c91;
	display: none;

	span {
		color: #fff; } }

.b-success-ticket_text {
	background: url(/assets/img/ticket-success.png)no-repeat;
	display: inline-block;
	padding: 3px 0 3px 30px; }

.dataTables_wrapper {
	margin: 0 -65px 0;
	padding: 0 65px; }

.b-ticket-body {
	background: #fff!important; }

.b-page-tabs._hide-border {
	border-bottom: none; }
