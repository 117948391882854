// DASHBOARD
.b-main-wrapper.login-layout {
	.wrapper_n {
		opacity: 0;
		visibility: hidden; } }

.b-main-wrapper {
	min-height: 100%;

	.wrapper_n {
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease; }

	.b-content-wrapper {
		width: 72%;
		margin: 0 auto; }

	.b-content-news {
		padding: 30px 0; }

	.b-content-news-list {
		padding: 20px 0;
		border-bottom: 1px solid #c4cacd;
		box-shadow: 0 -1px 0 rgb(215,221,224) inset; }

	.b-content-title-new {
		display: inline-block;
		vertical-align: top;

		span {
			background: #3bb9a8;
			font-size: 20px;
			color: #fff;
			padding: 14px 23px 15px;
			margin: 1px 45px 0 0;
			display: inline-block; } }

	.b-content-news-text {
		display: inline-block;
		vertical-align: top;
		margin-top: -3px;
		height: 65px;
		overflow: hidden;
		position: relative;
		transition: max-height 0.1s ease;
		font-family: "RobotoRegular",sans-serif;

		p {
			font-family: "RobotoRegular",sans-serif;
			margin-bottom: 0; } }

	.b-visible-text {

		p {

			&:nth-child(2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap; }

			&:nth-child(3) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap; } } }

	.b-hidden-text {
		position: absolute;
		top: 25px;
		opacity: 0;

		p {
			overflow: visible!important;
			text-overflow: inhert!important;
			white-space: normal!important; } }

	.b-hidden-text._show {
		opacity: 1; }

	.b-visible-text {
		opacity: 1; }

	.b-visible-text._hide {
		opacity: 0; }

	.b-content-news-title {
		font-family: "RobotoBold",sans-serif;
		display: inline-block;
		margin-bottom: 6px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 12px; }

	.b-content-news-open {
		position: relative;
		float: right;
		cursor: pointer;

		.i-edit {
			width: 22px;
			height: 22px;
			background: url(/assets/img/open-news.png)no-repeat 0 0; }

		.i-edit-invis {
			background-position: 0 -30px; } }

	.b-content-news-open._open {
		.i-edit-invis {
			opacity: 1; } }

	.b-content-news-learn-more {
		color: #2db3a1;
		transition: all 0.3s ease;
		text-decoration: underline;
		font-family: "RobotoMedium", sans-serif;

		&:hover {
			color: #259183; } }

	.b-content-inline-block {
		display: inline-block;
		vertical-align: top; }

	.b-content-stats {
		word-spacing: -0.25em;
		margin-bottom: 55px; }

	.b-content-stats__left {
		@extend .b-content-inline-block; }

	.b-content-stats__right {
		@extend .b-content-inline-block; }

	.b-content-stats__item {
		height: 160px;
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		word-spacing: -0.25em;
		margin-bottom: 11px;
		position: relative;
		transition: all 0.3s ease;

		.b-content-stats__item {
			word-spacing: 0; }

		a {
			text-decoration: none;

			&:hover {
				.i-edit-sp-ico_invis {
					opacity: 1;
					visibility: visible; }

				.b-inbox-count {
					box-shadow: 0 0 3px rgba(0,0,0,.8);

					&:after {
						box-shadow: 0 0 3px rgba(0,0,0,.8); } } } }

		.b-content-stats__title {
			word-spacing: 0em; }

		.i-edit_sp-ico {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -25px 0 0 -25px;
			width: 57px;
			height: 56px; }

		.i-edit_earning-ico {
			background: url(/assets/img/sp_ico.png)no-repeat 0 -5px;
			+ .i-edit-sp-ico_invis {
				background-position: 0 -61px; } }

		.i-edit_channels-ico {
			background: url(/assets/img/sp_ico.png)no-repeat -80px -5px;
			+ .i-edit-sp-ico_invis {
				background-position: -80px -61px; } }

		.i-edit_ref-ico {
			background: url(/assets/img/sp_ico.png)no-repeat -160px -5px;
			+ .i-edit-sp-ico_invis {
				background-position: -160px -61px; } }

		.i-edit_profile-ico {
			background: url(/assets/img/sp_ico.png)no-repeat -240px -5px;
			+ .i-edit-sp-ico_invis {
				background-position: -240px -61px; } }

		.i-edit_new-ico {
			background: url(/assets/img/sp_ico.png)no-repeat -320px -5px;
			+ .i-edit-sp-ico_invis {
				background-position: -320px -61px; } }

		.i-edit_inbox-ico {
			background: url(/assets/img/sp_ico.png)no-repeat -400px -5px;
			+ .i-edit-sp-ico_invis {
				background-position: -400px -61px; } }

		.i-edit-sp-ico_invis {
			opacity: 0;
			visibility: visible;
			transition: all 0.3s ease; }

		.b-inbox-count {
			font-size: 17px;
			top: -5px;
			line-height: 21px;
			right: initial;
			left: 39px;
			padding: 1px 6px;
			box-shadow: none;
			transition: box-shadow 0.3s ease;
			z-index: 999;

			&:after {
				content: '';
				position: absolute;
				width: 9px;
				height: 9px;
				display: block;
				background: #3bb9a8;
				border-radius: 100%;
				right: -2px;
				top: -3px;
				box-shadow: none;
				transition: box-shadow 0.3s ease; } }

		.b-content-stats-item_left {
			width: 130px;
			text-align: center;
			padding: 12px 0 0 0;
			margin: 0 0 0 -25px;
			position: relative;
			border-right: 1px solid rgba(191,191,191,.3); }

		.b-content-stats-item_center {
			border-left: 1px solid rgba(0,0,0,.2);

			span {
				display: inline-block;
				vertical-align: middle; } }

		.b-content-stats__number {
			font-family: "RobotoBold"; }

		.b-content-stats__text {
			line-height: 18px;
			word-spacing: 0; }

		.b-content-stats-item_right {
			width: 31.26%;
			border-left: 1px solid rgba(0,0,0,.2); }

		.b-content-stats-item-tight_right {
			border-left: 1px solid rgba(0, 0, 0,.2); }

		.b-content-stats__container {
			display: table;
			width: 100%;
			height: 100%;

			.b-content-stats__container-inner {
				display: table-cell;
				vertical-align: middle; } }

		.b-content-stats__down-desc {
			// position: absolute
			// bottom: 21px
			font-size: 12px; }

		.b-down-desc_1 {
			white-space: nowrap; }

		.b-down-desc_2 {
			display: block; } }

	.b-content-stats-item_wide {
		width: 100%; }

	.b-content-stats-item_green {
		background: #3bb9a8;

		&:hover {
			background: #259183; }

		a {
			color: #fff; } }

	.b-content-stats-item_orange {
		background: #ff5300;

		&:hover {
			background: #d54b08; }
		a {
			color: #fff; } }

	.b-content-stats-item_white {
		background: rgba(255,255,255,.75);

		&:hover {
			background: #fff; }
		a {
			color: #000; }

		.b-arrow_w {
			background: url(/assets/img/views_b.png)no-repeat; } }

	.b-arrow {
		padding: 0 0 0 14px; }

	.b-arrow_b {
		background: url(/assets/img/views_b.png)no-repeat; }

	.b-arrow_w {
		background: url(/assets/img/views_w.png)no-repeat; }

	.b-arrow_up {
		background-position: 0 0; }

	.b-arrow_down {
		background-position: 0 -40px!important; }

	.b-content-stats-tight_right {
		border-left: 1px solid rgba(0,0,0,.4); }

	.b-content-stats-right_invis {
		margin-bottom: 45px;

		.b-content-stats__item {
			height: 250px;
			margin-right: 2.6%;
			padding: 0;
			text-align: center;
			&:last-of-type {
				margin-right: 0; }
			.b-content-stats-item_up {
				height: 130px;
				padding-top: 8px;
				position: relative;
				border-bottom: 1px solid rgba(191,191,191,.2);

				.b-inbox-count {
					top: -4px;
					right: -1px; }

				.i-edit_sp-ico {
					margin: 0 0 0 -28px;
					top: 55px; } }

			.b-content-stats-item_down {
				padding-top: 40px;
				border-top: 1px solid rgba(191,191,191,.5);
				word-spacing: 0;

				.b-content-stats__down-desc {
					position: relative;
					left: 0;
					bottom: -2px; } } } }

	.limiter {
		height: 149px; } }
.modal-unite {
	padding: 60px 60px!important; }

.b-desc-margin {
	display: inline-block; }

.b-down-desc_1 {
	+ .b-earning-span-count.b-report {
		margin-top: 25px; } }
// DASHBOARD END
