.b-support-page-block {
	background: transparent;
	padding: 0 0 30px; }

.b-support-select {
	word-spacing: -0.25em; }

.b-support-item {
	vertical-align: top;
	width: 33.3333%;
	cursor: pointer;
	transition: all 0.3s ease;
	overflow: hidden;
	position: relative;

	.i-edit {
		width: 92px;
		height: 100px;
		margin-bottom: 2px;
		background: url(/assets/img/support-big-ico.png) no-repeat 2px 5px; }

	span {
		opacity: 1;
		position: relative;
		transition: opacity 0.2s ease; }

	.i-edit-invis {
		opacity: 0;
		position: absolute; }

	&:hover {
		.i-edit-invis {
			opacity: 1; } } }

.b-support-item._open {
	width: 72%; }

.b-support-item._close {
	width: 14%; }

.b-select-item-container {
	display: table;
	width: 100%;
	height: 270px;
	text-align: center;
	color: #fff; }

.b-select-item-inner {
	display: table-cell;
	vertical-align: middle; }

.b-ico-wrap {
	position: relative;
	display: inline-block; }

.i-edit-instructions {
	background-position: 2px -90px!important; }

.i-edit-search {
	background-position: 2px -193px!important; }

.b-support-item.orange {
	.i-edit-invis {
		background-position: -99px 4px; }
	&:hover {
		background: #d54b08; } }


.b-support-item.green {
	.i-edit-invis {
		background-position: -99px -104px!important; }
	&:hover {
		background: #37998d; } }


.b-support-item.gray {
	.i-edit-invis {
		background-position: -99px -212px!important; }
	&:hover {
		background: #757a7b; } }


.sup-3._open {
	span {
		opacity: 0; }

	.b-search-container-inner {
		right: 0; }

	&:hover {
		.i-edit-invis {
			opacity: 0; } } }

.sup-3 {
	.b-search-wrap.clearfix {
		position: absolute;
		top: 50%;
		width: 100%;
		padding: 0 8.4%;
		margin-top: -18px; }

	.b-search-container {
		overflow: hidden; }

	.b-search-container-inner {
		position: relative;
		right: -499px;
		transition: all 0.3s ease; }

	.b-search-btn {
		margin-bottom: -37px;
		margin-right: -1px; }

	.b-search-input {
		float: none;
		width: 100%; } }

.b-support-list {
	background: #fff;
	padding: 30px 60px 60px; }

.b-support-list-item {
	overflow: hidden;

	&:nth-child(2n) {
		background: #ebeded; } }

.b-arrow-wrap {
	position: relative;
	vertical-align: middle;

	.i-edit-invis {
		background-position: -9px -200px; } }

.i-edit-support {
	width: 26px;
	height: 26px;
	vertical-align: middle!important; }

.i-edit-question {
	background: url(/assets/img/support_sprite.png)no-repeat 0 -30px; }

.i-edit-instruct {
	background: url(/assets/img/support_sprite.png)no-repeat 0 0; }

.i-edit-tickets {
	background: url(/assets/img/support_sprite.png)no-repeat 0 -60px; }

.i-edit-question.i-edit-invis {
	background-position: -30px -30px; }

.i-edit-instruct.i-edit-invis {
	background-position: -30px 0; }

.i-edit-tickets.i-edit-invis {
	background-position: -30px -60px; }

.b-support-item-header {
	padding: 12px 0px 14px 17px;
	border-left: 3px solid transparent;
	transition: border 0.3s ease;
	cursor: pointer;

	.i-edit-arrow {
		margin-top: 2px; }

	&:hover {
		.i-edit-invis {
			opacity: 1; } } }

.b-support-item-header._open {
	.i-edit-arrow {
		transform: rotate(90deg); } }

.b-header-question {
	&:hover {
		border-left: 3px solid #fb4000; } }

.b-header-instruct {
	&:hover {
		border-left: 3px solid #24b19e; } }

.b-support-title {
	vertical-align: middle;
	font: 16px "RobotoBold",sans-serif;
	margin-top: 2px;
	margin-left: 6px; }

.b-support-info {
	font-size: 16px;
	padding-top: 55px;

	.b-page-link {
		line-height: 25px;
		margin-top: 10px;
		margin-right: 27px;
		position: relative;
		text-decoration: none;

		span {
			text-decoration: underline; }

		&:hover {

			.i-edit-invis {
				opacity: 1; } } } }

.b-support-item-wrap {
	padding: 15px 30px 42px 100px; }

.b-support-item-body {
	max-height: 0;
	transition: all 0.3s ease; }

.b-support-item-body._show {
	max-height: 3000px;
	transition: all 2.3s ease; }

.b-support-item-text {
	p {
		font-family: "RobotoRegular",sans-serif; } }

.b-support-item-pdf {
	max-width: 80%;
	margin: 0 auto;
	position: relative; }

.b-support-pdf {
	max-width: 810px;
	margin: 0 auto;
	padding-bottom: 42px; }

.the-canvas {
	width: 100%!important;
	height: 605px!important; }

.pdf-pager {
	position: absolute;
	width: 18px;
	height: 25px;
	top: 50%;
	cursor: pointer;
	margin-top: -9px;
	background: url(/assets/img/support-arrow.png)no-repeat 0 0; }

.pdf-pager.disabled {}



.pdf-pager.prev {
	left: -50px;

	&:hover {
		background-position: 0 -28px; } }

.pdf-pager.prev.disabled {
	background-position: 0 0!important; }

.pdf-pager.next {
	right: -50px;
	background-position: -26px 0;

	&:hover {
		background-position: -26px -28px; } }

.pdf-pager.next.disabled {
	background-position: -26px 0!important; }

.dots-wrap {
	text-align: center; }

.dots {
	display: inline-block;
	height: 10px;
	width: 10px;
	background: #c6c8c9;
	border-radius: 100%;
	cursor: pointer;
	margin-right: 5px;
	transition: all 0.3s ease;

	&:hover {
		background: #27b29f; } }

.dots.active {
	background: #27b29f; }

